import { Typography, Button, TextField } from "@material-ui/core";
import React from "react";

interface IQuestionFreeformProps {
  questionText: string;
  isRequired: boolean;
  handleAnswer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNext: () => void;
  onBack: () => void;
  currentResponse: string[];
}

export const QuestionFreeform: React.FunctionComponent<IQuestionFreeformProps> = (
  props
) => {
  const currentResponseAnswer = props.currentResponse[0];
  return (
    <div>
      <Typography variant={"h5"}>{props.questionText}</Typography>
      <TextField
        style={{ display: "flex", marginTop: "1rem" }}
        value={currentResponseAnswer}
        onChange={props.handleAnswer}
        variant="outlined"
        multiline={true}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        <Button onClick={props.onBack}>{"Back"}</Button>
        <Button
          disabled={props.isRequired ? !currentResponseAnswer : false}
          variant="outlined"
          color="primary"
          onClick={props.onNext}
        >
          {!props.isRequired && !currentResponseAnswer ? "Skip" : "Next"}
        </Button>
      </div>
    </div>
  );
};
