import { Typography } from "@material-ui/core";
import React from "react";
import ImageUploader from "react-images-upload";

interface INewCompanyOnboardingLogoUploadProps {
  setCompanyLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export const NewCompanyOnboardingLogoUpload: React.FunctionComponent<INewCompanyOnboardingLogoUploadProps> = (
  props
) => {
  const handleCompanyLogoChange = (files: File[]): void => {
    props.setCompanyLogo(files[0]);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        {
          "Add your company logo to personalize your company's daily screening process. (Optional)"
        }
      </Typography>
      <ImageUploader
        withIcon={false}
        buttonText={"Upload company logo"}
        onChange={handleCompanyLogoChange}
        imgExtension={[".jpg", ".png"]}
        withPreview={true}
        maxFileSize={5242880}
        singleImage
      />
    </div>
  );
};
