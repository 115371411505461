import React from "react";

interface IDevErrorBoxProps {
  errorMessage: string;
}

export const DevErrorBox: React.FunctionComponent<IDevErrorBoxProps> = (
  props
) => {
  return (
    <div
      style={{
        height: "100px",
        width: "100px",
        backgroundColor: "red",
      }}
    >
      {props.errorMessage}
    </div>
  );
};
