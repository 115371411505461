import _ from "lodash";
import {
  TextField,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { InputHeaderSmall } from "./InputHeaderSmall";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { useBreakpointStyles } from "../../styles";
import { EmailImportDialog } from "./EmailImportDialog";

interface IEmailInputListProps {
  emailList: string[];
  onChange: (newEmailList: string[]) => void;
  placeholder: string;
  header?: string;
  enableCsvImport?: boolean;
}

export const EmailInputList: React.FunctionComponent<IEmailInputListProps> = (
  props
) => {
  const breakpointStyles = useBreakpointStyles();
  const [emailInput, setEmailInput] = useState<string>("");
  const [isCsvImportDialogOpen, setIsCsvImportDialogOpen] = useState<boolean>(
    false
  );
  const doesEmailAlreadyExist = (email: string): boolean => {
    return props.emailList.includes(email);
  };
  const handleEmailInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailInput(event.target.value);
  };
  const handleAddCompanyEmail = () => {
    if (emailInput && !doesEmailAlreadyExist(emailInput)) {
      const updatedEmailList = [...props.emailList, emailInput];
      setEmailInput("");
      props.onChange(updatedEmailList);
    }
  };
  const handleImportMassEmails = (newEmails: string[]) => {
    let updatedEmailList = _.union(props.emailList, newEmails);
    updatedEmailList = updatedEmailList.filter((email) => !!email);
    props.onChange(updatedEmailList);
  };
  const closeCsvImportDialog = () => {
    setIsCsvImportDialogOpen(false);
  };
  const openCsvImportDialog = () => {
    setIsCsvImportDialogOpen(true);
  };
  return (
    <div>
      {props.header && <InputHeaderSmall label={props.header} />}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          onChange={handleEmailInputChange}
          value={emailInput}
          type="email"
          variant="outlined"
          id="manager-email-input-field"
          placeholder={props.placeholder}
          style={{ marginRight: "0.3rem", display: "flex", flex: 1 }}
        />
        <Button
          onClick={handleAddCompanyEmail}
          variant="contained"
          color="primary"
        >
          <AddIcon />
        </Button>
        {props.enableCsvImport && (
          <Button
            onClick={openCsvImportDialog}
            variant="contained"
            color="secondary"
            style={{ marginLeft: "0.3rem" }}
          >
            <SaveAltIcon />
          </Button>
        )}
      </div>
      {props.emailList.length > 0 && (
        <List dense={false}>
          {props.emailList.map((email, index) => {
            const handleEmailRemoval = () => {
              const updatedEmailList = props.emailList.filter(
                (comEmail) => comEmail !== email
              );
              props.onChange(updatedEmailList);
            };
            return (
              <ListItem key={`${email}${index}`}>
                <ListItemAvatar
                  className={breakpointStyles.breakpointHideIfBelowSm}
                >
                  <Avatar />
                </ListItemAvatar>
                <ListItemText style={{ color: "gray" }} primary={email} />
                <ListItemSecondaryAction>
                  <Button onClick={handleEmailRemoval}>
                    <ClearIcon />
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      {props.enableCsvImport && (
        <EmailImportDialog
          isOpen={isCsvImportDialogOpen}
          closeDialog={closeCsvImportDialog}
          handleImportMassEmails={handleImportMassEmails}
        />
      )}
    </div>
  );
};
