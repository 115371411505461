import {
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableSortLabel,
  Toolbar,
  TextField,
  Menu,
  IconButton,
  MenuItem,
  TableContainer,
  Paper,
} from "@material-ui/core";
import React, { CSSProperties, useEffect, useState } from "react";
import {
  convertCompanyResultsToTableRows,
  transformTableDataToCsv,
} from "../../../utils";
import {
  ICompanyResultsOverTime,
  ICompanyResultsTableRows,
  TableRowData,
} from "../../../types";
import { PortalTabTitle } from "./PortalTabTitle";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DayJsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import UIUtils from "../../../uiUtils";
import { themeColors } from "../../../styles";
import { useDebounce } from "use-debounce";
import { memoize } from "lodash";

interface IPortalScreeningResultsProps {
  questionnaireResults: ICompanyResultsOverTime | undefined;
  fetchAndSetQuestionnaireResults: () => void;
}

type SortDirection = "asc" | "desc" | undefined;

interface SortConfig {
  columnKey: string;
  direction: SortDirection;
}

interface TableData {
  original: ICompanyResultsTableRows;
  mutable: ICompanyResultsTableRows;
}

export const PortalScreeningResults: React.FunctionComponent<IPortalScreeningResultsProps> = (
  props
) => {
  const [csvTableRowData, setCsvTableRowData] = useState<TableData>();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    columnKey: "date",
    direction: "desc",
  });
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nameQueryFilter, setNameQueryFilter] = useState("");
  const [debouncedNameQueryFilter] = useDebounce(nameQueryFilter, 300);

  useEffect(() => {
    if (!props.questionnaireResults) {
      props.fetchAndSetQuestionnaireResults();
    } else {
      const tableData = convertCompanyResultsToTableRows(
        props.questionnaireResults
      );
      setCsvTableRowData({
        original: tableData,
        mutable: {
          headerRow: tableData.headerRow,
          tableRows: sortTable(tableData.tableRows, "date", "desc", "date"),
        },
      });
      setIsLoading(false);
    }
  }, [props]);

  useEffect(() => {
    const sortTableType =
      sortConfig.columnKey === "date" ? "date" : "alphanumeric";

    if (!selectedDate) {
      setCsvTableRowData((prevTableData) => {
        if (!prevTableData) {
          return;
        }

        return {
          original: prevTableData.original,
          mutable: {
            headerRow: prevTableData.original.headerRow,
            tableRows: sortTable(
              prevTableData.original.tableRows,
              sortConfig.columnKey,
              sortConfig.direction,
              sortTableType
            ),
          },
        };
      });
    }

    const isValidDateSelected = selectedDate && dayjs(selectedDate).isValid();
    if (isValidDateSelected || debouncedNameQueryFilter) {
      setCsvTableRowData((prevTableData) => {
        if (!prevTableData) {
          return;
        }

        const filteredTable = memoizedFilterTable(
          prevTableData.original.tableRows,
          selectedDate,
          debouncedNameQueryFilter
        );
        const tableRows = sortTable(
          filteredTable,
          sortConfig.columnKey,
          sortConfig.direction,
          sortTableType
        );

        return {
          original: prevTableData.original,
          mutable: {
            headerRow: prevTableData.original.headerRow,
            tableRows,
          },
        };
      });
    }
  }, [selectedDate, sortConfig, debouncedNameQueryFilter]);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onExportToCsv = () => {
    if (!csvTableRowData || !csvTableRowData.mutable) {
      UIUtils.showSnackbar("No data available");
      onMenuClose();
      return;
    }

    window.location.href = transformTableDataToCsv(csvTableRowData.mutable);
    onMenuClose();
  };

  const handleNameQueryInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNameQueryFilter(event.target.value);
  };

  if (isLoading) {
    return <CircularProgress color="primary" />;
  }

  let shouldToggleTableRowStyle = false;

  return (
    <React.Fragment>
      <PortalTabTitle
        title={"Screening Results"}
        subtitle={"View your company's screening results"}
      />
      {csvTableRowData && csvTableRowData.mutable && (
        <TableContainer component={Paper}>
          <Toolbar
            variant={"dense"}
            style={{
              justifyContent: "flex-start",
              paddingRight: 0,
              paddingLeft: "1rem",
              marginBottom: "1rem",
            }}
          >
            <TextField
              onChange={handleNameQueryInputChange}
              value={nameQueryFilter}
              variant="outlined"
              id="name-query-filter-portal-screening-results"
              placeholder={"Search by name"}
              style={{ marginRight: "1rem" }}
            />
            <LocalizationProvider dateAdapter={DayJsUtils}>
              <DatePicker
                disableFuture={true}
                clearable={true}
                label="Search by day"
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                inputFormat={"MM-DD-YYYY"}
                renderInput={(props) => {
                  return <TextField {...props} />;
                }}
              />
            </LocalizationProvider>
            <IconButton aria-label="delete" onClick={onMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={onMenuClose}
            >
              <MenuItem onClick={onExportToCsv}>Export to CSV</MenuItem>
            </Menu>
          </Toolbar>
          <Table style={{ width: "max-content", overflowX: "scroll" }}>
            <TableHead>
              <TableRow>
                {csvTableRowData.mutable.headerRow.map((row) => {
                  const isActive = sortConfig.columnKey === row.key;
                  const CellElement = row.custom ? (
                    row.name
                  ) : (
                    <TableSortLabel
                      active={isActive}
                      direction={isActive ? sortConfig.direction : "asc"}
                      onClick={() => {
                        const direction =
                          row.key !== sortConfig.columnKey
                            ? "asc"
                            : sortConfig.direction === "asc"
                            ? "desc"
                            : "asc";
                        setSortConfig({
                          columnKey: row.key,
                          direction,
                        });
                      }}
                    >
                      {row.name}
                    </TableSortLabel>
                  );

                  return (
                    <TableCell style={{ fontWeight: "bold" }} key={row.key}>
                      {CellElement}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {csvTableRowData.mutable.tableRows.map((tableRow, index) => {
                const tableRowStyle: CSSProperties = {};
                if (
                  shouldToggleTableRowStyle &&
                  sortConfig.columnKey === "date"
                ) {
                  tableRowStyle.backgroundColor = themeColors.tableGray;
                }
                if (
                  tableRow.date !==
                  (csvTableRowData?.mutable.tableRows[index + 1] &&
                    csvTableRowData.mutable.tableRows[index + 1].date)
                ) {
                  shouldToggleTableRowStyle = !shouldToggleTableRowStyle;
                }

                const customQuestionHeaderRows = csvTableRowData.original.headerRow.filter(
                  (row) => typeof row.custom !== "undefined" || row.custom
                );
                const statusStyles: CSSProperties = {};

                if (tableRow.isCleared) {
                  statusStyles.color = themeColors.greenSuccess;
                } else {
                  statusStyles.color = themeColors.redAlert;
                }

                return (
                  <TableRow style={tableRowStyle} key={tableRow.id}>
                    <TableCell align={"left"}>{tableRow.date}</TableCell>
                    <TableCell>{tableRow.firstName}</TableCell>
                    <TableCell>{tableRow.lastName}</TableCell>
                    <TableCell>{tableRow.email}</TableCell>
                    <TableCell style={statusStyles}>
                      {tableRow.isCleared ? "Cleared" : "Failed"}
                    </TableCell>
                    {customQuestionHeaderRows.map((headerRowData, index) => {
                      const cellText = tableRow.customQuestionAnswerPairs[
                        headerRowData.name
                      ]
                        ? tableRow.customQuestionAnswerPairs[headerRowData.name]
                        : "-";
                      return <TableCell key={index}>{cellText}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

const sortTable = (
  tableRows: TableRowData[] = [],
  sortBy: string,
  direction: SortDirection,
  type?: "date" | "alphanumeric"
) => {
  return tableRows.sort((x, y) => {
    const sortPredicates = {
      asc: x[sortBy] > y[sortBy],
      desc: x[sortBy] < y[sortBy],
    };

    if (type && type === "date") {
      sortPredicates.asc = dayjs(x[sortBy], getDateFormat(x[sortBy])).isAfter(
        dayjs(y[sortBy], getDateFormat(y[sortBy]))
      );
      sortPredicates.desc = dayjs(x[sortBy], getDateFormat(x[sortBy])).isBefore(
        dayjs(y[sortBy], getDateFormat(y[sortBy]))
      );
    }

    if (sortPredicates.asc) {
      return direction === "asc" ? 1 : -1;
    }

    if (sortPredicates.desc) {
      return direction === "desc" ? 1 : -1;
    }

    return 0;
  });
};

const filterTable = (
  tableData: TableRowData[] = [],
  date?: Date | null,
  nameQuery?: string
) => {
  return tableData.filter((row) => {
    let shouldReturnByDate = true;
    let shouldReturnByNameQuery = true;
    if (date) {
      const parsedFromDate = dayjs(row.date, getDateFormat(row.date));
      shouldReturnByDate = dayjs(parsedFromDate).isSame(date, "date");
    }

    if (nameQuery) {
      shouldReturnByNameQuery =
        row.firstName.toLowerCase().includes(nameQuery.toLowerCase()) ||
        row.lastName.toLowerCase().includes(nameQuery.toLowerCase()) ||
        row.email.toLowerCase().includes(nameQuery.toLowerCase());
    }

    return shouldReturnByDate && shouldReturnByNameQuery;
  });
};

const memoizedFilterTable = memoize(filterTable, (...args) => {
  return `${args[1]?.toString()}-${args[2]}`
});

const getDateFormat = (date: string): string => {
  const defaultDateFormat = "MM-DD-YYYY";
  let dateFormat = defaultDateFormat;
  const dateFormats = [defaultDateFormat, "MM-D-YYYY", "M-DD-YYYY", "M-D-YYYY"];

  for (let i = 0; i < dateFormats.length; i++) {
    if (dayjs(date, dateFormats[i]).isValid()) {
      dateFormat = dateFormats[i];
      break;
    }
  }

  return dateFormat;
};
