/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import {
  ICompanyResultPerEmployee,
  ICompanyResultsOverTime,
  ICompanyResultsTableRows,
  PortalTabType,
} from "./types";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { SKIP_VACCINE_QUESTION_KEY } from "./constants";

dayjs.extend(isBetween);

export const generateQuestionnaireNavigationRoute = (
  companyId: string,
  shouldSkipVaccineQuestion?: boolean
): string => {
  let route = `/questionnaire/${companyId}`;
  if (shouldSkipVaccineQuestion) {
    route += `?${SKIP_VACCINE_QUESTION_KEY}=true`;
  }

  return route;
};

export const generateQuestionnaireFullLink = (companyId: string): string => {
  return `https://simplyscreen.health/questionnaire/${companyId}`;
};

export const generateVaccineVerificationNavigationRoute = (
  companyId: string
): string => {
  return `/vaccineVerification/${companyId}`;
};

export const generateAdminCsvExportRoute = (
  companyId: string,
  companyName: string
): string => {
  return `/adminCsvExport/${companyId}/${companyName}`;
};

export const generateAdminEditCompanyRoute = (companyId: string): string => {
  return `/adminEditCompany/${companyId}`;
};

export const generateAdminGodPortalRoute = (companyId: string): string => {
  return `/adminGodPortal/${companyId}`;
};

export const howManyHoursAgo = (pastDate: Date): number => {
  const now = new Date();
  // 1000 = seconds
  // 60 = minutes
  // 60 = hours
  return Math.round(
    Math.abs(pastDate.getTime() - now.getTime()) / 1000 / 60 / 60
  );
};

export const extractContactTracingResultsFromCompanyResults = (
  resultsObj: ICompanyResultsOverTime,
  reportedDate: Date | null
): ICompanyResultPerEmployee[] => {
  const resultsPerEmployee: ICompanyResultPerEmployee[] = [];
  if (!reportedDate) {
    return resultsPerEmployee;
  }

  Object.keys(resultsObj).forEach((date) => {
    if (dayjs(date).isAfter(dayjs(reportedDate).subtract(3, "day"))) {
      const employeeResultsForDate = resultsObj[date].results;
      employeeResultsForDate.forEach((employeeResult) => {
        if (
          resultsPerEmployee.filter(
            (trackedEmployee) => trackedEmployee.email === employeeResult.email
          ).length === 0
        ) {
          resultsPerEmployee.push(employeeResult);
        }
      });
    }
  });
  return resultsPerEmployee;
};

export const extractTodayResultsFromCompanyResults = (
  resultsObj: ICompanyResultsOverTime
): { totalCount: number; clearedCount: number; failedCount: number } => {
  const now = new Date();
  const todayResults = { totalCount: 0, clearedCount: 0, failedCount: 0 };
  Object.keys(resultsObj).forEach((date) => {
    if (dayjs(date).isSame(now, "day")) {
      const employeeResultsForDate = resultsObj[date].results;
      employeeResultsForDate.forEach((employeeResult) => {
        todayResults.totalCount = todayResults.totalCount + 1;
        if (employeeResult.isCleared) {
          todayResults.clearedCount = todayResults.clearedCount + 1;
        } else {
          todayResults.failedCount = todayResults.failedCount + 1;
        }
      });
    }
  });
  return todayResults;
};

export const convertCompanyResultsToCsvCompatible = (
  resultsObj: ICompanyResultsOverTime
): string => {
  const csvRows = transformCompanyResults(resultsObj);
  const csvContent =
    "data:text/csv;charset=utf-8," + csvRows.map((e) => e.join(",")).join("\n");
  return encodeURI(csvContent);
};

export const transformTableDataToCsv = (
  tableData: ICompanyResultsTableRows
): string => {
  const headerRow = tableData.headerRow.map((row) => row.name);
  const csvRows = [headerRow];

  const customQuestionHeaderRows = tableData.headerRow.filter(
    (row) => typeof row.custom !== "undefined" || row.custom
  );

  tableData.tableRows.forEach((tableRow) => {
    const customQuestionCells = customQuestionHeaderRows.map(
      (headerRowData, index) => {
        return tableRow.customQuestionAnswerPairs[headerRowData.name]
          ? tableRow.customQuestionAnswerPairs[headerRowData.name]
          : "";
      }
    );

    const row = [
      tableRow.date,
      tableRow.firstName,
      tableRow.lastName,
      tableRow.email,
      tableRow.isCleared ? "Cleared" : "Not Cleared",
      ...customQuestionCells,
    ];

    csvRows.push(row);
  });

  const csvContent =
    "data:text/csv;charset=utf-8," + csvRows.map((e) => e.join(",")).join("\n");
  return encodeURI(csvContent);
};

export const convertCompanyResultsToTableRows = (
  resultsObj: ICompanyResultsOverTime
): ICompanyResultsTableRows => {
  const headerRow: ICompanyResultsTableRows["headerRow"] = [
    { name: "Date", key: "date" },
    { name: "First Name", key: "firstName" },
    { name: "Last Name", key: "lastName" },
    { name: "Email", key: "email" },
    { name: "Status", key: "isCleared" },
  ];
  const tableRows: ICompanyResultsTableRows["tableRows"] = [];

  for (const [key, value] of Object.entries(resultsObj)) {
    value.results.forEach((result) => {
      for (const [key] of Object.entries(result.customQuestionAnswerPairs)) {
        if (
          !headerRow.find((row) => {
            return row.name === key;
          })
        ) {
          headerRow.push({
            name: key,
            key,
            custom: true,
          });
        }
      }
      tableRows.push({
        ...result,
        id: uuidv4(),
        date: key,
      });
    });
  }

  return {
    headerRow,
    tableRows,
  };
};

const transformCompanyResults = (resultsObj: ICompanyResultsOverTime) => {
  // Initialize the standard title row
  let csvRows: string[][] = [
    ["Date", "FirstName", "LastName", "Email", "Status"],
  ];

  // Add custom question texts to the title row
  const customQuestionTextsArray: string[] = [];
  Object.keys(resultsObj).forEach((date) => {
    const employeeResultsForDate = resultsObj[date].results;
    employeeResultsForDate.forEach((employeeResult) => {
      const customQuestionAnswerPairs =
        employeeResult.customQuestionAnswerPairs;

      if (customQuestionAnswerPairs) {
        Object.keys(customQuestionAnswerPairs).forEach((questionText) => {
          if (!customQuestionTextsArray.includes(questionText)) {
            customQuestionTextsArray.push(questionText);
            csvRows[0] = [...csvRows[0], questionText];
          }
        });
      }
    });
  });

  // Populate the employee results
  Object.keys(resultsObj).forEach((date) => {
    const employeeResultsForDate = resultsObj[date].results;
    employeeResultsForDate.forEach((employeeResult) => {
      const newEmployeeRow = [
        date,
        employeeResult.firstName,
        employeeResult.lastName,
        employeeResult.email,
        employeeResult.isCleared ? "Cleared" : "NotCleared",
      ];

      customQuestionTextsArray.forEach((customQText) => {
        newEmployeeRow.push(
          employeeResult.customQuestionAnswerPairs[customQText] || "NoAnswer"
        );
      });
      csvRows.push(newEmployeeRow);
    });
  });

  return csvRows;
};

export const transposeNumbersToWeekdays = (index: number): string => {
  switch (index) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Unrecognized index";
  }
};

export const transposeWeekdaysToNumbers = (weekday: string): number => {
  switch (weekday) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
    default:
      return 99;
  }
};

const generateHourMinuteFromTimeArray = (
  timeArray: number[]
): { hour: string; minute: string } => {
  const hour =
    `${timeArray[0]}`.length === 1 ? `0${timeArray[0]}` : `${timeArray[0]}`;
  const minute =
    `${timeArray[1]}`.length === 1 ? `0${timeArray[1]}` : `${timeArray[1]}`;
  return {
    hour,
    minute,
  };
};

export const transposeTimeArrayToTimeString = (timeArray: number[]): string => {
  const hour =
    `${timeArray[0]}`.length === 1 ? `0${timeArray[0]}` : `${timeArray[0]}`;
  const minute =
    `${timeArray[1]}`.length === 1 ? `0${timeArray[1]}` : `${timeArray[1]}`;
  return `${hour}:${minute}`;
};

export const transposeTimeStringToTimeArray = (
  timeString: string
): number[] => {
  return [
    Number(timeString.slice(0, 2)),
    Number(timeString.slice(3, 5).toString()),
  ];
};

export const offsetTimeArrayIntoLocalTimezone = (
  timeArray: number[]
): number[] => {
  const stringiedTime = generateHourMinuteFromTimeArray(timeArray);
  const newDate = new Date();
  newDate.setUTCHours(Number(stringiedTime.hour), Number(stringiedTime.minute));
  return [newDate.getHours(), newDate.getMinutes()];
};

export const offsetTimeArrayIntoUTCTimezone = (
  timeArray: number[]
): number[] => {
  const stringiedTime = generateHourMinuteFromTimeArray(timeArray);
  const localDate = new Date();
  localDate.setHours(Number(stringiedTime.hour));
  localDate.setMinutes(Number(stringiedTime.minute));
  return [localDate.getUTCHours(), localDate.getUTCMinutes()];
};

export const convertPortalTabTypeToIndex = (tab: PortalTabType): number => {
  switch (tab) {
    case PortalTabType.Home:
      return 0;
    case PortalTabType.ScreeningResults:
      return 1;
    case PortalTabType.EmailReminders:
      return 2;
    case PortalTabType.EditCompanyInfo:
      return 3;
    case PortalTabType.AlertCovidCase:
      return 4;
    default:
      return 0;
  }
};

export const convertIndexToPortalTabType = (index: number): PortalTabType => {
  switch (index) {
    case 0:
      return PortalTabType.Home;
    case 1:
      return PortalTabType.ScreeningResults;
    case 2:
      return PortalTabType.EmailReminders;
    case 3:
      return PortalTabType.EditCompanyInfo;
    case 4:
      return PortalTabType.AlertCovidCase;
    default:
      return PortalTabType.Home;
  }
};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const convertNumberToPinCode = (num: number): string => {
  let stringifiedNum = String(num);
  const zerosToAdd = 5 - stringifiedNum.length;
  for (let index = 0; index < zerosToAdd; index++) {
    stringifiedNum = "0" + stringifiedNum;
  }
  return stringifiedNum;
};

export const generateUniquePinCode = (usedPinCodes: string[]): string => {
  // generate random number between 0 and 99999
  const newNumberForPinCode = Math.floor(Math.random() * (99999 - 0 + 1)) + 0;
  const newPinCode = convertNumberToPinCode(newNumberForPinCode);
  if (usedPinCodes.includes(newPinCode)) {
    // Inefficiently brute forced, but whatever
    return generateUniquePinCode(usedPinCodes);
  } else {
    return newPinCode;
  }
};
