import { Typography } from "@material-ui/core";
import React from "react";
import { StateCode } from "../../../types";
import { StateCodeDropdown } from "../../common/StateCodeDropdown";

interface INewCompanyOnboardingStateCodeProps {
  companyStateCode: StateCode;
  setCompanyStateCode: React.Dispatch<React.SetStateAction<StateCode>>;
}

export const NewCompanyOnboardingStateCode: React.FunctionComponent<INewCompanyOnboardingStateCodeProps> = (
  props
) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        {"What state does your company operate out of?"}
      </Typography>
      <StateCodeDropdown
        currentStateCode={props.companyStateCode}
        onChange={props.setCompanyStateCode}
      />
    </div>
  );
};
