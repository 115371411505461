import { Typography, Button } from "@material-ui/core";
import React from "react";
import { themeColors } from "../../../styles";
import { PaperWrapper } from "../../common/PaperWrapper";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

interface IPortalHomeCovidCaseProps {
  goToAlertCovidCaseTab: () => void;
}

export const PortalHomeCovidCase: React.FunctionComponent<IPortalHomeCovidCaseProps> = (
  props
) => {
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: "1rem" }}>
        Report a positive COVID-19 case
      </Typography>
      <PaperWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          fullWidth={true}
          startIcon={<NotificationsActiveIcon />}
          style={{ color: themeColors.redAlert, padding: "19px" }} // padding is to match height of other grid item
          variant="outlined"
          onClick={props.goToAlertCovidCaseTab}
        >
          {"Send out alert"}
        </Button>
      </PaperWrapper>
    </>
  );
};
