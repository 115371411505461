import {
  Button,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  OutlinedTextFieldProps,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { ICompanyResultsOverTime } from "../../../types";
import { extractContactTracingResultsFromCompanyResults } from "../../../utils";
import { PaperWrapper } from "../../common/PaperWrapper";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { themeColors } from "../../../styles";
import { PortalTabTitle } from "./PortalTabTitle";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DayJsUtils from "@date-io/dayjs";
import { LocalizationProvider, MobileDatePicker } from "@material-ui/pickers";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import UIUtils from "../../../uiUtils";
import { emailService } from "../../../services/firestore";

interface IPortalReportCovidCaseProps {
  companyName: string;
  questionnaireResults: ICompanyResultsOverTime | undefined;
  fetchAndSetQuestionnaireResults: () => void;
}

export const PortalReportCovidCase: React.FunctionComponent<IPortalReportCovidCaseProps> = (
  props
) => {
  const [emailMessage, setEmailMessage] = React.useState(
    `A positive COVID-19 case has been reported in the ${props.companyName} workspace. There is a potential that you have been exposed. If you have symptoms, you should self-isolate and follow CDC recommended steps: \n\nhttps://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html \n\nIf you have no symptoms, you should still remain at home or in a comparable setting and practice social distancing for 14 days.`
  );
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (!props.questionnaireResults) {
      props.fetchAndSetQuestionnaireResults();
    }
  });
  if (!props.questionnaireResults) {
    return <CircularProgress />;
  }

  const contactsResults = extractContactTracingResultsFromCompanyResults(
    props.questionnaireResults,
    selectedDate
  );

  const onCloseDialog = () => {
    setIsOpen(false);
  };

  const onOpenDialog = () => {
    if (!selectedDate) {
      UIUtils.showSnackbar("Please select a date");
      return;
    }

    if (emailMessage.length === 0) {
      UIUtils.showSnackbar("Please provide an email message");
      return;
    }

    if (contactsResults.length === 0) {
      UIUtils.showSnackbar("The contact list is empty");
      return;
    }

    setIsOpen(true);
  };

  const onSubmit = async () => {
    const emails = contactsResults.map((contact) => contact.email);

    try {
      await emailService.sendRedAlertToEmployees(emails, emailMessage);
      UIUtils.showSnackbar("Alert sent successfully");
    } catch (e) {
      UIUtils.showSnackbar("Alert failed to send, please try again");
    }
  };

  const formError = emailMessage.length === 0;

  return (
    <React.Fragment>
      <PortalTabTitle
        title={"Report a positive COVID case"}
        subtitle={
          "Let us do the contact tracing for you and alert all relevant persons"
        }
      />
      <Typography style={{ marginBottom: "0.5rem" }}>
        {
          "If someone entered your workspace and tested positive for COVID, use this button to send out an email blast to alert everyone else who entered the workspace in that time frame."
        }
      </Typography>
      <Typography>
        {
          "* No names will be used in this email, it will be anonymous and the COVID positive individual will retain full privacy."
        }
      </Typography>
      <Button
        size="large"
        startIcon={<NotificationsActiveIcon />}
        style={{ backgroundColor: themeColors.redAlert, margin: "1rem 0" }}
        color="primary"
        variant="contained"
        onClick={onOpenDialog}
      >
        {"Send out alert"}
      </Button>
      <PaperWrapper style={{ display: "flex", flexDirection: "column" }}>
        <LocalizationProvider dateAdapter={DayJsUtils}>
          <MobileDatePicker
            disableFuture={true}
            clearable={true}
            label="Select the date when the COVID-19 positive person entered the workspace."
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            renderInput={(props) => {
              const outlinedProps: OutlinedTextFieldProps = {
                ...props,
                variant: "outlined",
              };
              return <TextField {...outlinedProps} />;
            }}
          />
        </LocalizationProvider>
        <div style={{ marginTop: "2rem" }} />
        <TextField
          variant={"outlined"}
          onChange={(e) => setEmailMessage(e.target.value)}
          value={emailMessage}
          style={{ width: "100%" }}
          multiline={true}
          autoFocus
          margin="dense"
          id="name"
          label="Provide the email message that will be sent"
          type="text"
          fullWidth
          error={formError}
          helperText={formError ? "Required" : ""}
        />
        <div style={{ marginTop: "2rem" }} />
        <Typography variant={"subtitle2"}>{"Who will be alerted:"}</Typography>
        {contactsResults.length === 0 ? (
          <Button
            disabled={true}
            startIcon={<AssignmentTurnedInIcon />}
            variant="outlined"
          >
            {!selectedDate
              ? "Please select a date"
              : "No screenings recorded in the selected time period"}
          </Button>
        ) : (
          <List dense={true}>
            {contactsResults.map((contact, index) => {
              return (
                <ListItem key={`${contact.email}${index}`}>
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${contact.firstName} ${contact.lastName}`}
                    secondary={contact.email}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </PaperWrapper>
      <ConfirmationDialog
        isOpen={isOpen}
        title={"Send out COVID-19 alert email?"}
        closeDialog={onCloseDialog}
        onYes={onSubmit}
      />
    </React.Fragment>
  );
};
