import { Tooltip } from "@material-ui/core";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";

interface IHelpTooltipProps {
  tooltipText: string;
}

export const HelpTooltip: React.FunctionComponent<IHelpTooltipProps> = (
  props
) => {
  return (
    <div>
      <Tooltip disableFocusListener title={props.tooltipText}>
        <HelpIcon />
      </Tooltip>
    </div>
  );
};
