import { Typography, Button, TextField, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { authService } from "../../services/firestore";
import { PaperWrapper } from "../common/PaperWrapper";
import { Link, useHistory } from "react-router-dom";
import { NavigationRoute } from "../../types";
import { maxQuestionnaireWidth, themeColors } from "../../styles";
import { LogoHeader } from "../common/LogoHeader";
import WorkTimeRedSvg from "../../client-assets/work-time-red.svg";
import CheckIcon from "@material-ui/icons/Check";
import { ResetPasswordDialog } from "../common/ResetPasswordDialog";
import UIUtils from "../../uiUtils";

interface IManagerSignInSignUpProps {}

export const ManagerSignInSignUp: React.FunctionComponent<IManagerSignInSignUpProps> = (
  props
) => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [
    isForgotPasswordDialogOpen,
    setIsForgotPasswordDialogOpen,
  ] = useState<boolean>(false);
  const onSignIn = async () => {
    if (!email) {
      UIUtils.showSnackbar("Enter an email");
      return;
    }
    if (!password) {
      UIUtils.showSnackbar("Enter a password");
      return;
    }
    authService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        history.push(NavigationRoute.ManagerPortal);
      })
      .catch((error) => {
        UIUtils.showSnackbar("Incorrect email/password");
      });
  };
  const closeForgotPasswordDialog = () => {
    setIsForgotPasswordDialogOpen(false);
  };
  const openForgotPasswordDialog = () => {
    setIsForgotPasswordDialogOpen(true);
  };
  return (
    <div>
      <LogoHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: maxQuestionnaireWidth,
          margin: "auto",
        }}
      >
        <PaperWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CheckIcon
              color="primary"
              style={{ marginRight: "1rem", fontSize: "7rem" }}
            />
            <div>
              <Typography variant="h4" style={{ fontWeight: "bolder" }}>
                {"Your business."}
              </Typography>
              <Typography variant="h4" style={{ fontWeight: "bolder" }}>
                {"Open for business."}
              </Typography>
            </div>
          </div>
          <img
            src={WorkTimeRedSvg}
            style={{
              maxHeight: "8rem",
              marginLeft: "1rem",
              marginBottom: "-5px",
              marginTop: "2rem",
            }}
            alt={"Get SimplyScreen today for free"}
          />
          <Link
            style={{ display: "flex", textDecoration: "none" }}
            to={NavigationRoute.ManagerNewCompanyOnboarding}
          >
            <Button
              variant="contained"
              style={{
                flex: 1,
              }}
              color="primary"
              size="large"
            >
              {"Sign Up for free"}
            </Button>
          </Link>
          <Divider style={{ margin: "2rem 0" }} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{"Already have an account? Sign in."}</Typography>
            <TextField
              value={email}
              label={"Email"}
              type="email"
              id="manager-sign-in-email"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
            <TextField
              value={password}
              label={"Password"}
              type="password"
              id="manager-sign-in-password"
              autoComplete="current-password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={onSignIn}
              style={{ marginTop: "1rem" }}
            >
              {"Sign In"}
            </Button>
            <Typography
              onClick={openForgotPasswordDialog}
              style={{
                color: themeColors.linkBlue,
                marginTop: "1rem",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {"Forgot your password?"}
            </Typography>
          </div>
        </PaperWrapper>
      </div>
      <ResetPasswordDialog
        isOpen={isForgotPasswordDialogOpen}
        closeDialog={closeForgotPasswordDialog}
      />
    </div>
  );
};
