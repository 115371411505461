import { Typography } from "@material-ui/core";
import React from "react";

interface IInputHeaderSmallProps {
  label: string;
}

export const InputHeaderSmall: React.FunctionComponent<IInputHeaderSmallProps> = (
  props
) => {
  return (
    <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }} variant="caption">
      {props.label}
    </Typography>
  );
};
