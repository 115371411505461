import React, { useEffect, useState } from "react";
import { PaperWrapper } from "../common/PaperWrapper";
import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Company } from "../../types";
import FirestoreService from "../../services/firestore";
import {
  generateQuestionnaireNavigationRoute,
  generateAdminGodPortalRoute,
} from "../../utils";

interface IAdminCompaniesListProps {}

export const AdminCompaniesList: React.FunctionComponent<IAdminCompaniesListProps> = (
  props
) => {
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  useEffect(() => {
    FirestoreService.getAllCompanies().then((companies) => {
      setAllCompanies(companies);
    });
  }, []);
  return (
    <div>
      <PaperWrapper>
        <List dense={false}>
          {allCompanies.map((company, index) => {
            return (
              <ListItem key={`${company.id}`}>
                <ListItemAvatar>
                  <Avatar
                    src={
                      company.companyLogoUrl ||
                      "https://lh3.googleusercontent.com/proxy/HsFrseJGnRViC4krgWIqjLHKG6rUe1SimNQVeelnTgREY4u5mw1a-oDV57kJuBTNZ--8Zd8x1Un1aopuq2p1gx_hRUbIBwE_XJq-IrxHCMHrIQN_Yp_g8Bp2LQAdpQ"
                    }
                  />
                </ListItemAvatar>
                <ListItemText primary={company.companyName} />
                <ListItemSecondaryAction>
                  <Link to={generateQuestionnaireNavigationRoute(company.id)}>
                    <Button variant="outlined">{"Questionnaire"}</Button>
                  </Link>
                  <Link to={generateAdminGodPortalRoute(company.id)}>
                    <Button variant="outlined">{"Portal"}</Button>
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </PaperWrapper>
    </div>
  );
};
