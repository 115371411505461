import { CircularProgress, Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { convertCompanyResultsToCsvCompatible } from "../../utils";
import FirestoreService from "../../services/firestore";
import { PaperWrapper } from "../common/PaperWrapper";

interface IAdminCsvExportProps {}

interface IAdminCsvExportParams {
  companyId: string;
  companyName: string;
}

export const AdminCsvExport: React.FunctionComponent<IAdminCsvExportProps> = (
  props
) => {
  const { companyId, companyName } = useParams<IAdminCsvExportParams>();
  const [csvContent, setCsvContent] = useState<string>();

  useEffect(() => {
    FirestoreService.getCompanyQuestionnaireResultsOverTime(companyId).then(
      (companyQuestionnaireResults) => {
        if (companyQuestionnaireResults) {
          const convertedResults = convertCompanyResultsToCsvCompatible(
            companyQuestionnaireResults
          );

          setCsvContent(convertedResults);
        }
      }
    );
  }, [companyId]);

  if (!csvContent) {
    return <CircularProgress color="primary" />;
  }

  return (
    <div>
      <PaperWrapper>
        <Link
          href={csvContent}
          download={`${companyName} - Covid Daily Screening Report`}
        >
          {"For all time"}
        </Link>
      </PaperWrapper>
      <PaperWrapper></PaperWrapper>
    </div>
  );
};
