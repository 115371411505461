import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { StateCode } from "../../types";

interface IStateCodeDropdownProps {
  currentStateCode: StateCode;
  onChange: (stateCode: StateCode) => void;
}

export const StateCodeDropdown: React.FunctionComponent<IStateCodeDropdownProps> = (
  props
) => {
  const handleCompanyStateCodeChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    props.onChange(event.target.value as StateCode);
  };
  // TODO - add all the states
  return (
    <FormControl
      id={"state-code-selector-formcontrol"}
      style={{ display: "flex" }}
    >
      <InputLabel id="state-code-selector-helper-label">
        {"Select your state"}
      </InputLabel>
      <Select
        labelId="state-code-selector-label"
        id="state-code-selector"
        value={props.currentStateCode}
        onChange={handleCompanyStateCodeChange}
      >
        {Object.keys(StateCode).map((stateCodeKey, index) => {
          return (
            <MenuItem value={stateCodeKey} key={`${stateCodeKey}${index}`}>
              {stateCodeKey}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
