import { Typography, Button, Dialog } from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";
import React, { useState } from "react";
import CheckmarkGreenSvg from "../../client-assets/checkmark-green.svg";
import ExclamationRedSvg from "../../client-assets/exclamation-red.svg";
import { SOLILOQUY_DEMO_COMPANY_ID } from "../../constants";
import { themeColors } from "../../styles";
import { EmailSelfButton } from "./EmailSelfButton";
import { UserResultsInfo } from "../common/UserResultsInfo";
//@ts-ignore
const QRCode = require("qrcode.react");

interface IResultsProps {
  companyId: string;
  isClear: boolean;
  isVaccinated: boolean;
  submissionDate?: Date;
  employeeFirstName?: string;
  employeeLastName?: string;
  employeeEmail?: string;
  customClearedText?: string;
  customNotClearedText?: string;
}

export const Results: React.FunctionComponent<IResultsProps> = (props) => {
  const [isQROpen, setIsQROpen] = useState<boolean>(false);
  const isContactUpsellButtonEnabled =
    props.companyId === SOLILOQUY_DEMO_COMPANY_ID;
  const isNameAvailable = props.employeeFirstName && props.employeeLastName;
  const resultsSvg = props.isClear ? CheckmarkGreenSvg : ExclamationRedSvg;
  const resultsClearedMessage = props.isClear ? "Cleared" : "Not Cleared";
  const resultsInstructionalMessage = props.isClear
    ? props.customClearedText || "You're cleared to go to work."
    : props.customNotClearedText ||
      "Do not go to work, stay home. Your employer has been notified and will contact you shortly with more information.";
  const resultsSubmissionDateMessage = !!props.submissionDate
    ? `${props.submissionDate.toLocaleDateString()}`
    : undefined;
  const resultsNameMessage = `${props.employeeFirstName} ${props.employeeLastName}`;
  const resultsEmailMessage = `${props.employeeEmail}`;
  const resultsRecordYourselfMessage =
    "If you need to show this result at the door, either show this screen on your phone or use one of the below options:";
  const qrCodeValue = `Status: ${resultsClearedMessage}\n${resultsSubmissionDateMessage}\n${
    isNameAvailable && resultsNameMessage
  }\n${props.employeeEmail && resultsEmailMessage}`;

  return (
    <div>
      {isContactUpsellButtonEnabled && (
        <Button
          className={"pulsingThang"}
          href={"https://simplyscreen.care/#contact"}
          style={{
            width: "100%",
            marginBottom: "1rem",
            color: "white",
            backgroundImage: `linear-gradient(to right, ${themeColors.secondary}, ${themeColors.tertiary}, ${themeColors.primary})`,
          }}
        >
          {"Contact Us To Start Today!"}
        </Button>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h4">{resultsClearedMessage}</Typography>
        <img
          alt={"symptomCheckResultsImage"}
          style={{ width: "50%" }}
          src={resultsSvg}
        />
      </div>
      <Typography
        variant="subtitle1"
        style={{ marginTop: "1rem", fontWeight: "bold" }}
      >
        {resultsInstructionalMessage}
      </Typography>
      <UserResultsInfo
        isVaccinated={props.isVaccinated}
        userName={isNameAvailable && resultsNameMessage}
        userEmail={props.employeeEmail}
        submissionDate={resultsSubmissionDateMessage}
      />
      <Typography
        variant="subtitle2"
        color="textSecondary"
        style={{ marginTop: "1.5rem" }}
      >
        {resultsRecordYourselfMessage}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {props.employeeEmail && props.submissionDate && (
          <EmailSelfButton
            employeeEmail={props.employeeEmail}
            employeeName={`${props.employeeFirstName} ${props.employeeLastName}`}
            submissionDate={props.submissionDate}
            isClear={props.isClear}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<CropFreeIcon />}
          style={{ marginTop: "0.5rem" }}
          onClick={() => setIsQROpen(true)}
        >
          {"Scan QR for results"}
        </Button>
        <Dialog open={isQROpen} onClose={() => setIsQROpen(false)}>
          <div style={{ padding: "2rem" }}>
            <QRCode value={qrCodeValue} />
          </div>
        </Dialog>
      </div>
    </div>
  );
};
