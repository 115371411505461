import { Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router";
import ReactCodeInput from "react-code-input";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Link } from "react-router-dom";
import { generateQuestionnaireNavigationRoute } from "../../utils";
import FirestoreService from "../../services/firestore";
import uiUtils from "../../uiUtils";
import { IVaccinatedMember } from "../../types";

interface IVaccinePinCodeProps {
  onVerifySuccess: (vaccinatedMember: IVaccinatedMember) => void;
}
interface IVaccinePinCodeParams {
  companyId: string;
}

export const VaccinePinCode: React.FunctionComponent<IVaccinePinCodeProps> = (
  props
) => {
  const { onVerifySuccess } = props;
  const { companyId } = useParams<IVaccinePinCodeParams>();
  const [pinCodeInput, setPinCodeInput] = useState<string>("");
  const onPinCodeInputChange = (newPinCode: string) => {
    setPinCodeInput(newPinCode);
  };
  const verifyPinCode = () => {
    if (pinCodeInput.length !== 5) {
      uiUtils.showSnackbar("Type in your 5 digit pin");
      return;
    }

    FirestoreService.getVaccinatedMember(companyId, pinCodeInput)
      .then((vaccinatedMember) => {
        if (!vaccinatedMember) {
          uiUtils.showSnackbar("Pin not found");
          return;
        }

        onVerifySuccess(vaccinatedMember);
      })
      .catch((err) => {
        console.error(
          `[VaccinePinCode].verifyPinCode, getVaccinatedMember failed`,
          { err }
        );
      });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" style={{ marginBottom: "0.5rem" }}>
          {"Enter your employer provided vaccine passport pin"}
        </Typography>
        <ReactCodeInput
          name="pin-code-input-field"
          inputMode="numeric"
          fields={5}
          value={pinCodeInput}
          onChange={onPinCodeInputChange}
        />
        <Button
          startIcon={<VerifiedUserIcon />}
          variant="contained"
          color="primary"
          style={{ width: "8rem", marginTop: "1rem" }}
          onClick={verifyPinCode}
        >
          {"Verify"}
        </Button>
      </div>
      <div style={{ marginTop: "5rem" }}>
        <Typography variant="subtitle1" style={{ marginBottom: "0.5rem" }}>
          {"Don't know your pin?"}
        </Typography>
        <Link
          to={generateQuestionnaireNavigationRoute(companyId, true)}
          style={{ textDecoration: "none" }}
        >
          <Button variant="outlined">{"Screen for symptoms instead"}</Button>
        </Link>
      </div>
    </>
  );
};
