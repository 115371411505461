import { AppBar, Typography } from "@material-ui/core";
import React from "react";
import SimplyScreenLogoSvg from "../../client-assets/simply-screen-logo.svg";

interface ILogoHeaderProps {}

export const LogoHeader: React.FunctionComponent<ILogoHeaderProps> = (
  props
) => {
  return (
    <AppBar
      color="transparent"
      position="relative"
      style={{
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      <img
        style={{ height: "2.5rem", padding: "0.5rem" }}
        alt={"Onboarding company logo"}
        src={SimplyScreenLogoSvg}
      />
      <Typography variant="h6" style={{ fontWeight: "lighter" }}>
        {"SimplyScreen"}
      </Typography>
    </AppBar>
  );
};
