import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Company,
  ICustomQuestion,
  IQuestionnaireResults,
  IQuestionnaireResultSeverityMessage,
  StateCode,
} from "../../types";
import FirestoreService, { emailService } from "../../services/firestore";
import { CircularProgress, Typography } from "@material-ui/core";
import {
  CovidQuestionFormat,
  CovidQuestionId,
  covidQuestions,
  defaultQuestionnaireResetHours,
  IAnswers,
  IAnswerVariables,
  covidSymptomsChecklistAnalyzer,
  isQuestionIdCustom,
  ICovidQuestion,
  filterToCustomQuestionAnswerPairs,
} from "./covidQuestions";
import { QuestionRadio } from "./QuestionRadio";
import { EmployeeInfo } from "./EmployeeInfo";
import { Results } from "./Results";
import {
  getLastQuestionnaireResults,
  getVaccinePinCode,
  saveQuestionnaireResults,
} from "../../services/localStorage";
import {
  generateVaccineVerificationNavigationRoute,
  howManyHoursAgo,
} from "../../utils";
import { PaperWrapper } from "../common/PaperWrapper";
import { maxQuestionnaireWidth } from "../../styles";
import { QuestionCheckmark } from "./QuestionCheckmark";
import { QuestionFreeform } from "./QuestionFreeform";
import { REFERRAL_CODE_KEY } from "../../constants";
import { QuestionnaireHeader } from "./QuestionnaireHeader";

interface IQuestionnaireProps {}
interface IQuestionnaireParams {
  companyId: string;
}

export const Questionnaire: React.FunctionComponent<IQuestionnaireProps> = (
  props
) => {
  const { companyId } = useParams<IQuestionnaireParams>();
  const currHistory = useHistory();
  const isVaccinePinCodeSave = getVaccinePinCode();
  if (isVaccinePinCodeSave) {
    const vaccineScreenRoute = generateVaccineVerificationNavigationRoute(companyId);
    currHistory.push(vaccineScreenRoute);
  }
  const currLocation = useLocation();
  const referralCode = new URLSearchParams(currLocation.search).get(
    REFERRAL_CODE_KEY
  );
  const lastQuestionnaireResults = getLastQuestionnaireResults();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<Company>();
  const [answers, setAnswers] = useState<IAnswers>({});
  const [
    hasAttemptedEmployeeInfoSubmit,
    setHasAttemptedEmployeeInfoSubmit,
  ] = useState<boolean>(false);
  const [
    currentQuestionnaireResults,
    setCurrentQuestionnaireResults,
  ] = useState<IQuestionnaireResults>(lastQuestionnaireResults);
  const [
    temporaryQuestionnaireResultsBeforeCustom,
    setTemporaryQuestionnaireResultsBeforeCustom,
  ] = useState<{
    isClear: boolean;
    severityMessage: IQuestionnaireResultSeverityMessage;
  }>({
    isClear: true,
    severityMessage: IQuestionnaireResultSeverityMessage.Clear,
  });
  const [employeeFirstName, setEmployeeFirstName] = useState<string>("");
  const [employeeLastName, setEmployeeLastName] = useState<string>("");
  const [employeeEmail, setEmployeeEmail] = useState<string>("");
  // This determines which question to start on
  const [questionIdStack, setQuestionIdStack] = useState<
    (CovidQuestionId | string)[]
  >([CovidQuestionId.LifeThreateningSymptoms]);
  // Used to keep track of variables needed in the medical algorithm
  const [answerVariables, setAnswerVariables] = useState<IAnswerVariables>({});
  // Always start on employee info screen
  const [isEmployeeInfoScreen, setIsEmployeeInfoScreen] = useState<boolean>(
    true
  );
  const [currentCustomQuestionIndex, setCurrentCustomQuestionIndex] = useState<
    number | undefined
  >(undefined);
  // Override all screens if last results within X hours.
  // Always give a few hours leeway, employee needs to fill out
  // the questionnaire each morning give or take an hour or so.
  const shouldOverrideWithRecentQuestionnaireResults = lastQuestionnaireResults.submissionDate
    ? howManyHoursAgo(lastQuestionnaireResults.submissionDate) <=
      defaultQuestionnaireResetHours
      ? true
      : false
    : false;
  const [isResultsScreen, setIsResultsScreen] = useState<boolean>(
    shouldOverrideWithRecentQuestionnaireResults
  );

  const addDefaultCustomQuestionsToCompany = (company: Company): void => {
    if (company.companyCustomQuestions) {
      company.companyCustomQuestions.unshift({
        ...covidQuestions[CovidQuestionId.VaccineGeneral],
        isRequired: true,
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    FirestoreService.getCompany(companyId)
      .then((company) => {
        if (company) {
          addDefaultCustomQuestionsToCompany(company);
          setCompanyData(company);
          if (
            company.companyCustomQuestions &&
            company.companyCustomQuestions.length > 0
          ) {
            setCurrentCustomQuestionIndex(0);
          }
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId]);

  const getCurrentCustomQuestion = (): ICustomQuestion | undefined => {
    if (
      companyData?.companyCustomQuestions &&
      currentCustomQuestionIndex !== undefined
    ) {
      return companyData.companyCustomQuestions[currentCustomQuestionIndex];
    }
  };

  const currentQuestionId = questionIdStack[questionIdStack.length - 1];
  let currentQuestion: ICovidQuestion | ICustomQuestion = {} as
    | ICovidQuestion
    | ICustomQuestion;
  if (isQuestionIdCustom(currentQuestionId)) {
    const currentCustomQuestion = getCurrentCustomQuestion();
    if (currentCustomQuestion) {
      currentQuestion = currentCustomQuestion;
    }
  } else {
    currentQuestion = covidQuestions[currentQuestionId];
  }
  const handleRadioAnswer = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAnswers({
      ...answers,
      [currentQuestionId]: [e.target.value],
    });
  };
  const handleFreeformAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswers({
      ...answers,
      [currentQuestionId]: [event.target.value],
    });
  };
  const handleCheckmarkAnswer = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    const currentCheckmarkAnswers = answers[currentQuestionId];

    if (currentCheckmarkAnswers) {
      if (isChecked) {
        setAnswers({
          ...answers,
          [currentQuestionId]: [...currentCheckmarkAnswers, name],
        });
      } else {
        setAnswers({
          ...answers,
          [currentQuestionId]: [...currentCheckmarkAnswers].filter(
            (answerOption) => answerOption !== name
          ),
        });
      }
    } else {
      if (isChecked) {
        setAnswers({
          ...answers,
          [currentQuestionId]: [name],
        });
      }
    }
  };

  const addQuestionToStack = (questionId: CovidQuestionId | string): void => {
    setQuestionIdStack(questionIdStack.concat(questionId));
  };

  const popQuestionFromStack = (): void => {
    if (
      isQuestionIdCustom(currentQuestionId) &&
      currentCustomQuestionIndex !== undefined &&
      currentCustomQuestionIndex > 0
    ) {
      setCurrentCustomQuestionIndex(currentCustomQuestionIndex - 1);
    }
    setQuestionIdStack(questionIdStack.slice(0, questionIdStack.length - 1));
  };

  const isFirstQuestion = (): boolean => {
    return questionIdStack.length === 1;
  };

  const isFinalCustomQuestion = (): boolean => {
    if (
      companyData?.companyCustomQuestions &&
      currentCustomQuestionIndex !== undefined
    ) {
      return !companyData.companyCustomQuestions[
        currentCustomQuestionIndex + 1
      ];
    } else {
      return true;
    }
  };

  const addNextCustomQuestionToStack = (): void => {
    if (
      companyData &&
      companyData.companyCustomQuestions &&
      currentCustomQuestionIndex !== undefined &&
      companyData.companyCustomQuestions[currentCustomQuestionIndex + 1]
    ) {
      const nextCustomQuestion =
        companyData.companyCustomQuestions[currentCustomQuestionIndex + 1];
      addQuestionToStack(nextCustomQuestion.questionText);
      setCurrentCustomQuestionIndex(currentCustomQuestionIndex + 1);
    }
  };

  const isVaccinated = (): boolean => {
    const customQuestionAnswerPairs = filterToCustomQuestionAnswerPairs(
      answers
    );

    return (
      customQuestionAnswerPairs[
        covidQuestions[CovidQuestionId.VaccineGeneral].questionText
      ] === "Yes"
    );
  };

  const goToResultsScreenAndSaveResults = (
    isClear: boolean,
    severityMessage: IQuestionnaireResultSeverityMessage
  ): void => {
    if (companyData) {
      setIsLoading(true);
      const customQuestionAnswerPairs = filterToCustomQuestionAnswerPairs(
        answers
      );
      if (referralCode) {
        customQuestionAnswerPairs[REFERRAL_CODE_KEY] = referralCode;
      }
      FirestoreService.saveQuestionnaireResults(companyId, {
        email: employeeEmail,
        firstName: employeeFirstName,
        lastName: employeeLastName,
        isCleared: isClear,
        customQuestionAnswerPairs,
      })
        .then(() => {
          const questionnaireResults = saveQuestionnaireResults(
            isClear,
            severityMessage,
            isVaccinated()
          );
          if (!isClear && companyData.companyEmails?.length > 0) {
            emailService.sendFailedResultsToEmployer(
              companyData.companyEmails,
              employeeEmail,
              `${employeeFirstName} ${employeeLastName}`,
              questionnaireResults.submissionDate,
              companyId
            );
          }
          setCurrentQuestionnaireResults(questionnaireResults);
          setIsResultsScreen(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
        });
    } else {
      setIsError(true);
    }
  };

  const maybeGoToCustomQuestionOrResults = (
    isClear: boolean,
    severityMessage: IQuestionnaireResultSeverityMessage
  ) => {
    if (currentCustomQuestionIndex === undefined) {
      goToResultsScreenAndSaveResults(isClear, severityMessage);
    } else {
      const currentCustomQuestion = getCurrentCustomQuestion();
      if (currentCustomQuestion?.questionText) {
        setTemporaryQuestionnaireResultsBeforeCustom({
          isClear,
          severityMessage,
        });
        addQuestionToStack(currentCustomQuestion.questionText);
      }
    }
  };

  const onNext = (): void => {
    if (isEmployeeInfoScreen) {
      if (!!employeeFirstName && !!employeeLastName && !!employeeEmail) {
        setIsEmployeeInfoScreen(false);
      } else {
        setHasAttemptedEmployeeInfoSubmit(true);
      }
      return;
    }

    if (isQuestionIdCustom(currentQuestionId)) {
      if (isFinalCustomQuestion()) {
        goToResultsScreenAndSaveResults(
          temporaryQuestionnaireResultsBeforeCustom.isClear,
          temporaryQuestionnaireResultsBeforeCustom.severityMessage
        );
      } else {
        addNextCustomQuestionToStack();
      }
      return;
    }

    if (currentQuestionId === CovidQuestionId.LifeThreateningSymptoms) {
      if (answers[CovidQuestionId.LifeThreateningSymptoms][0] === "Yes") {
        maybeGoToCustomQuestionOrResults(
          false,
          IQuestionnaireResultSeverityMessage.Symptomatic
        ); // urgent MSG4
      } else {
        addQuestionToStack(CovidQuestionId.TestedPositive);
      }
      return;
    }

    if (currentQuestionId === CovidQuestionId.TestedPositive) {
      if (answers[CovidQuestionId.TestedPositive][0] === "Yes") {
        maybeGoToCustomQuestionOrResults(
          false,
          IQuestionnaireResultSeverityMessage.Symptomatic
        ); // TODO - add new positiveCase status and alert employer
      } else {
        if (companyData?.companyStateCode === StateCode.NY) {
          addQuestionToStack(CovidQuestionId.StateTravelRisk);
        } else {
          addQuestionToStack(CovidQuestionId.FeelingSick);
        }
      }
      return;
    }

    if (currentQuestionId === CovidQuestionId.StateTravelRisk) {
      if (answers[CovidQuestionId.StateTravelRisk][0] === "Yes") {
        maybeGoToCustomQuestionOrResults(
          false,
          IQuestionnaireResultSeverityMessage.Asymptomatic
        );
      } else {
        addQuestionToStack(CovidQuestionId.FeelingSick);
      }
      return;
    }

    if (currentQuestionId === CovidQuestionId.FeelingSick) {
      const isAsymptomatic = answers[CovidQuestionId.FeelingSick][0] === "No";
      setAnswerVariables({
        ...answerVariables,
        isAsymptomatic: isAsymptomatic,
      });
      if (isAsymptomatic) {
        addQuestionToStack(CovidQuestionId.CloseContactAsymp);
      } else {
        addQuestionToStack(CovidQuestionId.CovidSymptoms);
      }
      return;
    }

    // Fork between asymptomatic pathway
    if (answerVariables.isAsymptomatic) {
      // *
      // Asmyptomatic route
      // *
      if (currentQuestionId === CovidQuestionId.CloseContactAsymp) {
        if (answers[CovidQuestionId.CloseContactAsymp][0] === "No") {
          maybeGoToCustomQuestionOrResults(
            true,
            IQuestionnaireResultSeverityMessage.Clear
          );
        } else {
          addQuestionToStack(CovidQuestionId.LiveInFacility);
        }
        return;
      }

      if (currentQuestionId === CovidQuestionId.LiveInFacility) {
        if (answers[CovidQuestionId.LiveInFacility][0] === "Yes") {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Asymptomatic
          ); // eligible, contact someone at facility MSG_T2 - MSG25
        } else {
          addQuestionToStack(CovidQuestionId.HospitalWorker);
        }
        return;
      }

      if (currentQuestionId === CovidQuestionId.HospitalWorker) {
        if (answers[CovidQuestionId.HospitalWorker][0] === "No") {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Asymptomatic
          ); // eligible, stay home til you feel better MSG_T0 - MSG18
        } else {
          addQuestionToStack(CovidQuestionId.HospitalWorkerPPE);
        }
        return;
      }

      if (currentQuestionId === CovidQuestionId.HospitalWorkerPPE) {
        if (answers[CovidQuestionId.HospitalWorkerPPE][0] === "Yes") {
          maybeGoToCustomQuestionOrResults(
            true,
            IQuestionnaireResultSeverityMessage.Clear
          );
        } else {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Asymptomatic
          ); // eligible, contact someone at facility MSG_T2 - MSG15
        }
        return;
      }
    } else {
      // *
      // Symptomatic route
      // *
      if (currentQuestionId === CovidQuestionId.CovidSymptoms) {
        const symptoms = answers[CovidQuestionId.CovidSymptoms] || [];
        const analysis = covidSymptomsChecklistAnalyzer(symptoms);

        if (analysis.noSymptoms || analysis.onlyOther) {
          addQuestionToStack(CovidQuestionId.CloseContactSympto);
        } else if (analysis.onlySecondary) {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Symptomatic
          ); // eligible short-circuited
        } else if (analysis.anyPrimary) {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Symptomatic
          ); // urgent short-circuited
        }
        return;
      }

      if (currentQuestionId === CovidQuestionId.CloseContactSympto) {
        if (answers[CovidQuestionId.CloseContactSympto][0] === "No") {
          maybeGoToCustomQuestionOrResults(
            true,
            IQuestionnaireResultSeverityMessage.Clear
          );
        } else {
          maybeGoToCustomQuestionOrResults(
            false,
            IQuestionnaireResultSeverityMessage.Asymptomatic
          );
        }
        return;
      }
    }
  };

  const onBack = (): void => {
    if (isFirstQuestion()) {
      setIsEmployeeInfoScreen(true);
    } else {
      popQuestionFromStack();
    }
  };

  const mainComponentToRender = (): JSX.Element => {
    if (isLoading) {
      return <CircularProgress />;
    } else if (isError) {
      return (
        <Typography variant="h4">
          {"There was an issue. Please refresh the page and try again."}
        </Typography>
      );
    } else if (isResultsScreen) {
      return (
        <Results
          companyId={companyId}
          isClear={currentQuestionnaireResults.isClear || false}
          isVaccinated={currentQuestionnaireResults.isVaccinated || false}
          submissionDate={
            currentQuestionnaireResults.submissionDate || new Date()
          }
          employeeEmail={employeeEmail}
          employeeFirstName={employeeFirstName}
          employeeLastName={employeeLastName}
          customClearedText={companyData?.customClearedText}
          customNotClearedText={companyData?.customNotClearedText}
        />
      );
    } else if (isEmployeeInfoScreen) {
      return (
        <EmployeeInfo
          companyId={companyId}
          companyName={companyData?.companyName!}
          employeeFirstName={employeeFirstName}
          employeeLastName={employeeLastName}
          employeeEmail={employeeEmail}
          handleFirstNameChange={(e) => setEmployeeFirstName(e.target.value)}
          handleLastNameChange={(e) => setEmployeeLastName(e.target.value)}
          handleEmailChange={(e) => setEmployeeEmail(e.target.value)}
          hasAttemptedSubmit={hasAttemptedEmployeeInfoSubmit}
          onStart={onNext}
          customIntroText={companyData?.customIntroText}
          disableVaccinePassport={companyData?.disableVaccinePassportQuestion}
        />
      );
    } else {
      switch (currentQuestion.format) {
        case CovidQuestionFormat.Radio:
          let questionTextToShow = currentQuestion.questionText;
          if (
            currentQuestionId === CovidQuestionId.StateTravelRisk &&
            companyData?.companyStateCode
          ) {
            questionTextToShow = currentQuestion.questionText.replace(
              / *\([^)]*\) */g,
              ` ${companyData?.companyStateCode} `
            );
          }
          return (
            <QuestionRadio
              questionText={questionTextToShow}
              answerOptions={currentQuestion.answerOptions}
              handleAnswer={handleRadioAnswer}
              isRequired={
                isQuestionIdCustom(currentQuestionId)
                  ? (currentQuestion as ICustomQuestion).isRequired
                  : true
              }
              onNext={onNext}
              onBack={onBack}
              selectedAnswer={
                answers[currentQuestionId] ? answers[currentQuestionId][0] : ""
              }
              questionBulletpoints={currentQuestion.questionBulletpoints}
            />
          );
        case CovidQuestionFormat.Checkmark:
          return (
            <QuestionCheckmark
              questionText={currentQuestion.questionText}
              answerOptions={currentQuestion.answerOptions}
              handleAnswer={handleCheckmarkAnswer}
              onNext={onNext}
              onBack={onBack}
              selectedAnswers={answers[currentQuestionId] || []}
            />
          );
        case CovidQuestionFormat.Freeform:
          return (
            <QuestionFreeform
              questionText={currentQuestion.questionText}
              handleAnswer={handleFreeformAnswer}
              onNext={onNext}
              onBack={onBack}
              isRequired={
                isQuestionIdCustom(currentQuestionId)
                  ? (currentQuestion as ICustomQuestion).isRequired
                  : true
              }
              currentResponse={answers[currentQuestionId] || []}
            />
          );
        default:
          return (
            <div style={{ width: "2rem", height: "2rem", color: "red" }} />
          );
      }
    }
  };

  return (
    <div>
      <QuestionnaireHeader companyId={companyId} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ maxWidth: maxQuestionnaireWidth, margin: "auto" }}>
            <PaperWrapper>{mainComponentToRender()}</PaperWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
