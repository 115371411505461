import { Dialog, DialogTitle, Button } from "@material-ui/core";
import React from "react";

interface IConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  closeDialog: () => void;
  onYes: () => void;
  onNo?: () => void;
}

export const ConfirmationDialog: React.FunctionComponent<IConfirmationDialogProps> = (
  props
) => {
  const handleYes = () => {
    props.onYes();
    props.closeDialog();
  };
  const handleNo = () => {
    if (props.onNo) {
      props.onNo();
    }
    props.closeDialog();
  };
  return (
    <Dialog open={props.isOpen}>
      <DialogTitle id={`confirmation-dialog-title-${props.title}`}>
        {props.title}
      </DialogTitle>
      <Button
        style={{ margin: "0.5rem" }}
        variant="outlined"
        onClick={handleYes}
      >
        {"Yes"}
      </Button>
      <Button
        style={{ margin: "0.5rem" }}
        variant="outlined"
        onClick={handleNo}
      >
        {"No"}
      </Button>
    </Dialog>
  );
};
