import React from "react";
import { useParams } from "react-router-dom";
import { AdminNewCompany } from "./AdminNewCompany";

interface IAdminEditCompanyProps {
  companyIdViaProps?: string;
}

interface IAdminEditCompanyParams {
  companyId: string;
}

export const AdminEditCompany: React.FunctionComponent<IAdminEditCompanyProps> = (
  props
) => {
  const { companyId } = useParams<IAdminEditCompanyParams>();

  return (
    <AdminNewCompany
      companyId={companyId || props.companyIdViaProps}
      isEditMode={true}
    />
  );
};
