class UIUtils {
  public snackbarSetFxn: ((message: string) => void) | null;

  constructor() {
    this.snackbarSetFxn = null;
  }

  showSnackbar = (message: string): void => {
    if (this.snackbarSetFxn) {
      this.snackbarSetFxn(message);
    }
  };
}

export default new UIUtils();
