import { Typography } from "@material-ui/core";
import React from "react";
import { EmailInputList } from "../../common/EmailInputList";

interface INewCompanyOnboardingManagerEmailsProps {
  emailList: string[];
  setEmailList: React.Dispatch<React.SetStateAction<string[]>>;
}

export const NewCompanyOnboardingManagerEmails: React.FunctionComponent<INewCompanyOnboardingManagerEmailsProps> = (
  props
) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        {"Who do you want to receive urgent notifications about screenings?"}
      </Typography>
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "SimplyScreen will automatically notify managers if anyone fails their daily screening."
        }
      </Typography>
      <Typography variant="caption">
        {"(Enter at least one manager email)"}
      </Typography>
      <EmailInputList
        emailList={props.emailList}
        onChange={props.setEmailList}
        placeholder={"i.e. johnsmith@company.com"}
      />
    </div>
  );
};
