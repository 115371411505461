import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";

interface IQuestionRadioProps {
  questionText: string;
  answerOptions: string[];
  isRequired: boolean;
  handleAnswer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNext: () => void;
  onBack: () => void;
  selectedAnswer?: string;
  questionBulletpoints?: string[];
}

export const QuestionRadio: React.FunctionComponent<IQuestionRadioProps> = (
  props
) => {
  const renderBulletpoints = () => {
    if (props.questionBulletpoints) {
      return (
        <ul>
          {props.questionBulletpoints.map((bullet, index) => {
            return (
              <li key={`${bullet}${index}`} style={{ marginTop: "0.5rem" }}>
                <Typography
                  style={{ lineHeight: "1.2rem" }}
                  variant={"subtitle1"}
                >
                  {bullet}
                </Typography>
              </li>
            );
          })}
        </ul>
      );
    }
  };
  return (
    <div>
      <Typography variant={"h5"}>{props.questionText}</Typography>
      {renderBulletpoints()}
      <FormControl component="fieldset" style={{ marginTop: "1rem" }}>
        <RadioGroup
          aria-label="gender"
          name={props.questionText}
          onChange={props.handleAnswer}
          value={props.selectedAnswer}
        >
          {props.answerOptions.map((option, index) => {
            return (
              <FormControlLabel
                value={option}
                label={option}
                control={<Radio color="primary" />}
                key={`${option}${index}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        <Button onClick={props.onBack}>{"Back"}</Button>
        <Button
          disabled={props.isRequired ? !props.selectedAnswer : false}
          variant="outlined"
          color="primary"
          onClick={props.onNext}
        >
          {!props.isRequired && !props.selectedAnswer ? "Skip" : "Next"}
        </Button>
      </div>
    </div>
  );
};
