import { Button, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { generateVaccineVerificationNavigationRoute } from "../../utils";
import { PaperWrapper } from "../common/PaperWrapper";

interface IEmployeeInfoVaccineQuestionProps {
  companyId: string;
  goToEmployeeInfoEntryPhase: () => void;
}

export const EmployeeInfoVaccineQuestion: React.FunctionComponent<IEmployeeInfoVaccineQuestionProps> = (
  props
) => {
  return (
    <PaperWrapper style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" style={{ marginBottom: "1rem" }}>{"Have you been vaccinated?"}</Typography>
      <Link
        to={generateVaccineVerificationNavigationRoute(props.companyId)}
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" color="primary" style={{ width: "100%", marginBottom: "0.5rem" }}>
          {"Yes"}
        </Button>
      </Link>
      <Button
        variant="contained"
        color="primary"
        style={{ width: "100%" }}
        onClick={props.goToEmployeeInfoEntryPhase}
      >
        {"No"}
      </Button>
    </PaperWrapper>
  );
};
