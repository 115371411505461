export const REFERRAL_CODE_KEY = "referralCode";
export const SKIP_VACCINE_QUESTION_KEY = "skipVaccineQuestion";
export const SOLILOQUY_DEMO_COMPANY_ID = "6T5mAZ1atSa94OwsbHTp";
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_IS_TEST_MODE === "true" ? process.env.REACT_APP_TEST_STRIPE_PUBLIC_KEY : process.env.REACT_APP_STRIPE_PUBLIC_KEY
export const TestStripePrices = {
    StandardSubscription: 'price_1IbzusK84TCkYJPYsn4zyEs7'
}
export const ProdStripePrices = {
    StandardSubscription: 'price_1Id3ynK84TCkYJPY12s5OaqI'
}
export const StripePrices = process.env.REACT_APP_STRIPE_IS_TEST_MODE === "true" ? TestStripePrices : ProdStripePrices