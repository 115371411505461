import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const maxQuestionnaireWidth = "35rem";

export const themeColors = {
  primary: "#229b97", // teal
  secondary: "#FF2C5B", // red
  tertiary: "#9d2b76", // purple
  lightGray: "#f4f3f5",
  redAlert: "#e23a3a",
  linkBlue: "#7373f7",
  greenSuccess: "#44d323",
  tableGray: "#F5F5F5",
  textGray: "rgb(123 123 123)",
};

export const useBreakpointStyles = makeStyles((theme: Theme) =>
  createStyles({
    breakpointHideIfAboveSm: {
      [theme.breakpoints.up("sm")]: {
        display: "none !important",
      },
    },
    breakpointHideIfBelowSm: {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    breakpointFlexIfAboveSm: {
      [theme.breakpoints.up("sm")]: {
        display: "flex !important",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block !important",
      },
    },
    breakpointMinHeight5IfAboveSm: {
      [theme.breakpoints.up("sm")]: {
        minHeight: "5rem !important",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "3rem !important",
      },
    },
  })
);
