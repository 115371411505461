import {
  AppBar,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Hidden,
} from "@material-ui/core";
import React from "react";
import { authService, paymentService } from "../../../services/firestore";
import { ConfirmationDialog } from "../../common/ConfirmationDialog";
import MenuIcon from "@material-ui/icons/Menu";
import { PhoneNumberContact } from "../../common/PhoneNumberContact";
import uiUtils from "../../../uiUtils";
import { StripePrices, STRIPE_PUBLIC_KEY } from "../../../constants";

interface IPortalHeaderProps {
  companyName: string;
  companyLogoUrl?: string;
  stripUserId?: string;
  onClickMobileMenuToggle: () => void;
}

export const PortalHeader: React.FunctionComponent<IPortalHeaderProps> = (
  props
) => {
  const [
    settingsAnchor,
    setSettingsAnchor,
  ] = React.useState<null | HTMLElement>(null);
  const [isSignOutDialogOpen, setIsSignOutDialogOpen] = React.useState<boolean>(
    false
  );

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchor(event.currentTarget);
  };
  const handleSettingsMenuClose = () => {
    setSettingsAnchor(null);
  };
  const handleSignOut = () => {
    handleSettingsMenuClose();
    authService.signOut();
  };

  return (
    <AppBar
      position="fixed"
      style={{
        padding: "0.5rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Hidden smUp>
          <IconButton onClick={props.onClickMobileMenuToggle} color="inherit">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Typography style={{ marginLeft: "1rem" }}>
            {"SimplyScreen"}
          </Typography>
        </Hidden>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <PhoneNumberContact style={{ marginRight: "1rem" }} />
        <IconButton
          style={{ padding: 0, marginRight: "0.5rem" }}
          edge="end"
          onClick={handleSettingsClick}
          color="inherit"
        >
          <Avatar src={props.companyLogoUrl} />
        </IconButton>
      </div>
      <Menu
        id="portal-header-settings-menu"
        anchorEl={settingsAnchor}
        keepMounted
        open={Boolean(settingsAnchor)}
        onClose={handleSettingsMenuClose}
      >
        <PaymentMenuItem stripeCustomerId={props.stripUserId} />
        <MenuItem onClick={() => setIsSignOutDialogOpen(true)}>
          Sign out
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title={"Are you sure you want to sign out?"}
        onYes={handleSignOut}
        isOpen={isSignOutDialogOpen}
        closeDialog={() => setIsSignOutDialogOpen(false)}
      />
    </AppBar>
  );
};

interface PaymentMenuItemProps {
  stripeCustomerId?: string
}


const PaymentMenuItem = (props: PaymentMenuItemProps) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const onCheckoutClick = async () => {
    try {
      const { data: { sessionId } } = await paymentService.createCheckoutSession(StripePrices.StandardSubscription)
      // @ts-ignore
      const stripe: any = window.Stripe(STRIPE_PUBLIC_KEY);
      stripe
        .redirectToCheckout({
          sessionId
        })
        .then(console.log);
    } catch (error) {
      handleError(error)
    }
  }

  const onPortalClick = async (e: React.MouseEvent) => {
    try {
      const { data: { url } } = await paymentService.createCustomerPortal()
      window.location.href = url
    } catch (error) {
      handleError(error)
    }
  }

  const withIsLoading = (fn: Function) => {
    setIsLoading(true)
    fn()
  }

  const handleError = (error: Error) => {
    setIsLoading(false)
    uiUtils.showSnackbar('Service unavailable. Please try again later.')
  }

  const sharedProps: React.ComponentProps<typeof MenuItem> = {
    disabled: isLoading === true
  }

  if (!props.stripeCustomerId) {
    return (
      <MenuItem {...sharedProps} onClick={() => withIsLoading(onCheckoutClick)}>
        Subscribe
      </MenuItem>
    )
  }

  return (
    <MenuItem {...sharedProps} onClick={() => withIsLoading(onPortalClick)}>
      Manage Payments
    </MenuItem>
  )
}