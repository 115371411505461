import {
  IQuestionnaireResults,
  IQuestionnaireResultSeverityMessage,
} from "../types";

const questionnaireResultIsClearKey =
  "wellnessqapp-questionnaire-results-isClear";
const questionnaireResultSubmissionDateKey =
  "wellnessqapp-questionnaire-results-submissionDate";
const questionnaireResultSeverityMessage =
  "wellnessqapp-questionnaire-results-severityMessage";
const questionnaireResultVaccinatedKey =
  "wellnessqapp-questionnaire-results-isVaccinated";
const vaccinePassportPinCodeKey = "wellnessqapp-vaccinePassport-pinCode";

export const saveQuestionnaireResults = (
  isClear: boolean,
  severityMessage: IQuestionnaireResultSeverityMessage,
  isVaccinated: boolean
): {
  isClear: boolean;
  submissionDate: Date;
  severityMessage: IQuestionnaireResultSeverityMessage;
  isVaccinated: boolean;
} => {
  const submissionDate = new Date();
  localStorage.setItem(questionnaireResultIsClearKey, `${isClear}`);
  localStorage.setItem(questionnaireResultVaccinatedKey, `${isVaccinated}`);
  localStorage.setItem(
    questionnaireResultSubmissionDateKey,
    `${submissionDate}`
  );
  localStorage.setItem(
    questionnaireResultSeverityMessage,
    `${severityMessage}`
  );

  return {
    isClear,
    submissionDate,
    severityMessage,
    isVaccinated,
  };
};

export const getLastQuestionnaireResults = (): IQuestionnaireResults => {
  const isClearString = localStorage.getItem(questionnaireResultIsClearKey);
  const isVaccinatedString = localStorage.getItem(
    questionnaireResultVaccinatedKey
  );
  const submissionDateString = localStorage.getItem(
    questionnaireResultSubmissionDateKey
  );
  const severityMessage = localStorage.getItem(
    questionnaireResultSeverityMessage
  );

  let isClear;
  if (isClearString) {
    if (isClearString === "true") {
      isClear = true;
    } else if (isClearString === "false") {
      isClear = false;
    }
  }

  let isVaccinated;
  if (isVaccinatedString) {
    if (isVaccinatedString === "true") {
      isVaccinated = true;
    } else if (isVaccinatedString === "false") {
      isVaccinated = false;
    }
  }

  let submissionDate;
  if (submissionDateString) {
    submissionDate = new Date(submissionDateString);
  }

  return {
    isClear,
    isVaccinated,
    submissionDate,
    severityMessage: severityMessage as IQuestionnaireResultSeverityMessage,
  };
};

export const saveVaccinePinCode = (pinCode: string): void => {
  localStorage.setItem(vaccinePassportPinCodeKey, pinCode);
};

export const getVaccinePinCode = (): string | null => {
  return localStorage.getItem(vaccinePassportPinCodeKey);
};

export const clearVaccinePinCode = (): void => {
  localStorage.removeItem(vaccinePassportPinCodeKey);
};

export const clearQuestionnaireResults = (): void => {
  localStorage.removeItem(questionnaireResultIsClearKey);
  localStorage.removeItem(questionnaireResultSubmissionDateKey);
  localStorage.removeItem(questionnaireResultSeverityMessage);
  localStorage.removeItem(questionnaireResultVaccinatedKey);
  clearVaccinePinCode();
};
