import React, { useEffect, useState } from "react";
import { Company } from "../../types";
import FirestoreService from "../../services/firestore";
import { AppBar, CircularProgress, Typography } from "@material-ui/core";
import { clearQuestionnaireResults } from "../../services/localStorage";
import SimplyScreenLogoSvg from "../../client-assets/simply-screen-logo.svg";

interface IQuestionnaireHeaderProps {
  companyId: string;
}

export const QuestionnaireHeader: React.FunctionComponent<IQuestionnaireHeaderProps> = (
  props
) => {
  const { companyId } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<Company>();

  useEffect(() => {
    setIsLoading(true);
    FirestoreService.getCompany(companyId)
      .then((company) => {
        if (company) {
          setCompanyData(company);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [companyId]);

  return (
    <AppBar
      position={"static"}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem",
      }}
      color={"transparent"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <img
            alt={"companyLogo"}
            style={{ height: "3rem", marginRight: "0.7rem" }}
            src={companyData?.companyLogoUrl || SimplyScreenLogoSvg}
            onClick={() => {
              if (process.env?.NODE_ENV !== "production") {
                clearQuestionnaireResults();
              }
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{"COVID‑19 Screening"}</Typography>
      </div>
    </AppBar>
  );
};
