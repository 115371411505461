import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { maxQuestionnaireWidth } from "../../styles";
import { PaperWrapper } from "../common/PaperWrapper";
import { QuestionnaireHeader } from "../questionnaire/QuestionnaireHeader";
import { VaccineVerified } from "./VaccineVerified";
import { VaccinePinCode } from "./VaccinePinCode";
import { IVaccinatedMember } from "../../types";
import {
  getVaccinePinCode,
  saveVaccinePinCode,
} from "../../services/localStorage";
import FirestoreService from "../../services/firestore";
import { CircularProgress } from "@material-ui/core";

interface IVaccineVerificationProps {}
interface IVaccineVerificationParams {
  companyId: string;
}

export const VaccineVerification: React.FunctionComponent<IVaccineVerificationProps> = (
  props
) => {
  const { companyId } = useParams<IVaccineVerificationParams>();
  const storedPinCode = getVaccinePinCode();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [vaccinatedMember, setVaccinatedMember] = useState<IVaccinatedMember>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const onVerifySuccess = (fetchedVaccinedMember: IVaccinatedMember) => {
    saveVaccinePinCode(fetchedVaccinedMember.pinCode);
    setVaccinatedMember(fetchedVaccinedMember);
    setIsVerified(true);
  };

  useEffect(() => {
    if (storedPinCode) {
      FirestoreService.getVaccinatedMember(companyId, storedPinCode)
        .then((fetchedVaccinatedMember) => {
          if (fetchedVaccinatedMember) {
            onVerifySuccess(fetchedVaccinatedMember);
          }
        })
        .catch((err) => {
          console.error(
            `[VaccineVerification] locally stored pinCode failure`,
            { err }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [storedPinCode, companyId]);

  return (
    <div>
      <QuestionnaireHeader companyId={companyId} />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ maxWidth: maxQuestionnaireWidth, margin: "auto" }}>
              <PaperWrapper
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
              >
                {isVerified && vaccinatedMember ? (
                  <VaccineVerified
                    vaccinatedMember={vaccinatedMember}
                    companyId={companyId}
                  />
                ) : (
                  <VaccinePinCode onVerifySuccess={onVerifySuccess} />
                )}
              </PaperWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
