// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import {
  Company,
  ICompanyResultsOverTime,
  ICustomQuestion,
  StateCode,
  ICompanyResultPerEmployee,
  ICompanyDailyEmailReminders,
  NavigationRoute,
  IVaccinatedMember,
} from "../types";
import { generateUniquePinCode } from "../utils";

const firebaseConfig = {
  apiKey: "AIzaSyBJnHZOIEVKE6y56tGpzUlCjehvZ_c_gek",
  authDomain: "wellnessqapp.firebaseapp.com",
  databaseURL: "https://wellnessqapp.firebaseio.com",
  projectId: "wellnessqapp",
  storageBucket: "wellnessqapp.appspot.com",
  messagingSenderId: "39970187965",
  appId: "1:39970187965:web:a694aa35a205d3c0faa5d4",
  measurementId: "G-300ENGVDF2",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const cloudFunctions = firebase.functions();
const collectionPaths = {
  companies: "companies",
  questionnaireResults: "questionnaireResults",
  notifications: "notifications",
  users: "users",
  vaccinePassports: {
    primaryPath: "vaccinePassports",
    subCollectionPaths: {
      vaccinatedMembers: "vaccinatedMembers",
    },
  },
};
const storagePaths = {
  companyLogos: "companyLogos",
};

class FirestoreService {
  addNewCompany = async (
    companyName: string,
    companyEmails: string[],
    companyStateCode: StateCode,
    companyLogo?: File,
    companyCustomQuestions?: ICustomQuestion[],
    customIntroText?: string,
    customClearedText?: string,
    customNotClearedText?: string,
    customNotClearedEmailText?: string,
    preGeneratedCompanyId?: string
  ): Promise<string> => {
    const batch = db.batch();
    let newCompanyId: string;
    const companyInfo = this.generateCompanyDataWithoutUndefinedField(
      companyName,
      companyEmails,
      companyStateCode,
      companyCustomQuestions,
      customIntroText,
      customClearedText,
      customNotClearedText,
      customNotClearedEmailText
    );

    if (preGeneratedCompanyId) {
      newCompanyId = preGeneratedCompanyId;
    } else {
      const newCompanyDoc = db.collection(collectionPaths.companies).doc();
      newCompanyId = newCompanyDoc.id;
    }

    const newCompanyRef = db
      .collection(collectionPaths.companies)
      .doc(newCompanyId);
    batch.set(newCompanyRef, {
      ...companyInfo,
      createdAt: firebase.firestore.Timestamp.now(),
    });

    const newQuestionnaireResultsRef = db
      .collection(collectionPaths.questionnaireResults)
      .doc(newCompanyId);
    batch.set(newQuestionnaireResultsRef, {});

    const newNotificationsRef = db
      .collection(collectionPaths.notifications)
      .doc(newCompanyId);
    batch.set(newNotificationsRef, {
      employeeEmails: companyEmails,
      notificationsList: [
        {
          worker: "sendDailyEmail",
          days: [1, 2, 3, 4, 5], // Default = Mon - Fri
          time: [11, 0], // Default = 6am eastcoast
        },
      ],
    });

    const newVaccinePassportsRef = db
      .collection(collectionPaths.vaccinePassports.primaryPath)
      .doc(newCompanyId);
    batch.set(newVaccinePassportsRef, {
      usedPinCodes: [],
    });

    await batch.commit();

    if (companyLogo) {
      const companyLogoUrl = await this.uploadCompanyLogo(
        companyLogo,
        newCompanyId
      );
      const newCompanyDoc = db
        .collection(collectionPaths.companies)
        .doc(newCompanyId);
      await newCompanyDoc.update({
        companyLogoUrl,
      });

      return newCompanyId;
    } else {
      return newCompanyId;
    }
  };

  updateCompany = async (
    companyId: string,
    companyName?: string,
    companyEmails?: string[],
    companyStateCode?: StateCode,
    companyLogo?: File,
    companyCustomQuestions?: ICustomQuestion[],
    customIntroText?: string,
    customClearedText?: string,
    customNotClearedText?: string,
    customNotClearedEmailText?: string,
    noEmailPersist?: boolean,
    disableVaccinePassportQuestion?: boolean
  ): Promise<void> => {
    const updatedCompanyInfo = this.generateCompanyDataWithoutUndefinedField(
      companyName,
      companyEmails,
      companyStateCode,
      companyCustomQuestions,
      customIntroText,
      customClearedText,
      customNotClearedText,
      customNotClearedEmailText,
      noEmailPersist,
      disableVaccinePassportQuestion
    );
    const companyDoc = db.collection(collectionPaths.companies).doc(companyId);
    if (companyLogo) {
      const companyLogoUrl = await this.uploadCompanyLogo(
        companyLogo,
        companyId
      );

      updatedCompanyInfo.companyLogoUrl = companyLogoUrl;
      return companyDoc.update(updatedCompanyInfo);
    } else {
      return companyDoc.update(updatedCompanyInfo);
    }
  };

  getAllCompanies = async (): Promise<Company[]> => {
    const companiesSnapshot = await db
      .collection(collectionPaths.companies)
      .get();
    return companiesSnapshot.docs.map((company) => {
      const companyData = company.data();
      return {
        id: company.id,
        companyName: companyData.companyName,
        companyLogoUrl: companyData.companyLogoUrl,
        companyEmails: companyData.companyEmails,
        companyStateCode: companyData.companyStateCode,
        companyCustomQuestions: companyData.companyCustomQuestions,
        customIntroText: companyData.customIntroText,
        customClearedText: companyData.customClearedText,
        customNotClearedText: companyData.customNotClearedText,
        customNotClearedEmailText: companyData.customNotClearedEmailText,
        noEmailPersist: companyData.noEmailPersist,
        disableVaccinePassportQuestion: companyData.disableVaccinePassportQuestion,
        createdAt: companyData.createdAt.toDate(),
      };
    });
  };

  getCompany = async (companyId: string): Promise<Company | undefined> => {
    const companyDoc = await db
      .collection(collectionPaths.companies)
      .doc(companyId)
      .get();
    const companyData = companyDoc.data();
    if (companyData) {
      return {
        id: companyDoc.id,
        companyName: companyData.companyName,
        companyLogoUrl: companyData.companyLogoUrl,
        companyEmails: companyData.companyEmails,
        companyStateCode: companyData.companyStateCode,
        companyCustomQuestions: companyData.companyCustomQuestions,
        customIntroText: companyData.customIntroText,
        customClearedText: companyData.customClearedText,
        customNotClearedText: companyData.customNotClearedText,
        customNotClearedEmailText: companyData.customNotClearedEmailText,
        noEmailPersist: companyData.noEmailPersist,
        disableVaccinePassportQuestion: companyData.disableVaccinePassportQuestion,
        createdAt: companyData.createdAt.toDate(),
        stripeCustomerId: companyData.stripeCustomerId,
      };
    } else {
      console.error("Failed to fetch company");
    }
  };

  getCompanyForManager = async (): Promise<Company | undefined> => {
    const currentUser = authService.getCurrentUser();
    if (currentUser) {
      const companyId = await this.getCompanyIdForManager(currentUser.uid);
      return this.getCompany(companyId);
    } else {
      console.debug(
        `FirestoreService.getCompanyForManager - no current user exists`
      );
    }
  };

  saveQuestionnaireResults = async (
    companyId: string,
    employeeResultObj: ICompanyResultPerEmployee
  ): Promise<void> => {
    const companyResultsDoc = db
      .collection(collectionPaths.questionnaireResults)
      .doc(companyId);
    const dateStringId = new Date().toLocaleDateString().replace(/\//g, "-");

    return companyResultsDoc.update({
      [`${dateStringId}.resultCount`]: firebase.firestore.FieldValue.increment(
        1
      ),
      [`${dateStringId}.results`]: firebase.firestore.FieldValue.arrayUnion(
        employeeResultObj
      ),
    });
  };

  getCompanyQuestionnaireResultsOverTime = async (
    companyId: string
  ): Promise<ICompanyResultsOverTime | undefined> => {
    const questionnaireResultsDoc = await db
      .collection(collectionPaths.questionnaireResults)
      .doc(companyId)
      .get();
    const questionnaireResults = questionnaireResultsDoc.data();
    return questionnaireResults;
  };

  getCompanyEmailReminders = async (
    companyId: string
  ): Promise<ICompanyDailyEmailReminders | undefined> => {
    const dailyEmailRemindersDoc = await db
      .collection(collectionPaths.notifications)
      .doc(companyId)
      .get();
    const dailyEmailReminders = dailyEmailRemindersDoc.data();
    return dailyEmailReminders as ICompanyDailyEmailReminders | undefined;
  };

  saveCompanyEmailReminders = async (
    companyId: string,
    newReminderData: ICompanyDailyEmailReminders
  ): Promise<void> => {
    return db
      .collection(collectionPaths.notifications)
      .doc(companyId)
      .update(newReminderData);
  };

  addNewManagerUserWithNewCompany = async (
    managerUserId: string,
    companyName: string,
    companyEmails: string[],
    companyStateCode: StateCode,
    companyLogo?: File
  ): Promise<string> => {
    const newCompanyDoc = db.collection(collectionPaths.companies).doc();
    const newCompanyId = newCompanyDoc.id;
    await this.addNewManagerUser(managerUserId, newCompanyId);
    return this.addNewCompany(
      companyName,
      companyEmails,
      companyStateCode,
      companyLogo,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      newCompanyId
    );
  };

  getVaccinatedMember = async (
    companyId: string,
    pinCode: string
  ): Promise<IVaccinatedMember | undefined> => {
    const vaccinatedMemberRef = db
      .collection(collectionPaths.vaccinePassports.primaryPath)
      .doc(companyId)
      .collection(
        collectionPaths.vaccinePassports.subCollectionPaths.vaccinatedMembers
      )
      .doc(pinCode);

    const vaccinatedMemberDoc = await vaccinatedMemberRef.get();
    if (vaccinatedMemberDoc.exists) {
      return vaccinatedMemberDoc.data() as IVaccinatedMember;
    } else {
      return;
    }
  };

  getAllVaccinatedMembers = async (
    companyId: string
  ): Promise<IVaccinatedMember[]> => {
    const vaccinatedMembersRef = db
      .collection(collectionPaths.vaccinePassports.primaryPath)
      .doc(companyId)
      .collection(
        collectionPaths.vaccinePassports.subCollectionPaths.vaccinatedMembers
      );

    const vaccinatedMembersCollection = await vaccinatedMembersRef.get();
    const resultVaccinatedMembers: IVaccinatedMember[] = [];
    vaccinatedMembersCollection.forEach((vaccinatedMemberDoc) => {
      const vaccinatedMemberDocData = vaccinatedMemberDoc.data();
      resultVaccinatedMembers.push({
        firstName: vaccinatedMemberDocData.firstName,
        lastName: vaccinatedMemberDocData.lastName,
        email: vaccinatedMemberDocData.email,
        pinCode: vaccinatedMemberDocData.pinCode,
      });
    });
    return resultVaccinatedMembers;
  };

  addNewVaccinatedMember = async (
    companyId: string,
    vaccinatedMember: Omit<IVaccinatedMember, "pinCode">
  ): Promise<void> => {
    // Get used pin codes
    const vaccinePassportsRef = db
      .collection(collectionPaths.vaccinePassports.primaryPath)
      .doc(companyId);
    const vaccinePassportsDoc = await vaccinePassportsRef.get();
    const usedPinCodes = vaccinePassportsDoc.data()?.usedPinCodes as string[];
    // Generate a new, unique pin code
    const generatedPinCode = generateUniquePinCode(usedPinCodes || []);
    const newVaccinatedMemberObj: IVaccinatedMember = {
      ...vaccinatedMember,
      pinCode: generatedPinCode,
    };
    const batch = db.batch();
    const newVaccinatedMemberDoc = db
      .collection(collectionPaths.vaccinePassports.primaryPath)
      .doc(companyId)
      .collection(
        collectionPaths.vaccinePassports.subCollectionPaths.vaccinatedMembers
      )
      .doc(generatedPinCode);

    batch.set(newVaccinatedMemberDoc, newVaccinatedMemberObj);
    batch.update(vaccinePassportsRef, {
      usedPinCodes: firebase.firestore.FieldValue.arrayUnion(generatedPinCode),
    });

    return batch.commit();
  };

  private addNewManagerUser = (
    managerUserId: string,
    companyId: string
  ): Promise<void> => {
    return db.collection(collectionPaths.users).doc(managerUserId).set({
      managedCompanyId: companyId,
    });
  };

  private getCompanyIdForManager = async (
    managerUserId: string
  ): Promise<string> => {
    const managerData = await db
      .collection(collectionPaths.users)
      .doc(managerUserId)
      .get();
    if (managerData) {
      return managerData.data()?.managedCompanyId;
    } else {
      console.debug(
        `FirestoreService.getCompanyIdForManager - found no data for manager user`
      );
      return "";
    }
  };

  private uploadCompanyLogo = async (
    image: any,
    companyId: string
  ): Promise<string> => {
    const imageSnapshot = await this.generateCompanyLogoStoragePath(
      companyId
    ).put(image);
    const imageUrl = await imageSnapshot.ref.getDownloadURL();
    return imageUrl;
  };

  private generateCompanyLogoStoragePath = (
    companyId: string
  ): firebase.storage.Reference => {
    return storage.ref(`${storagePaths.companyLogos}/${companyId}`);
  };

  private generateCompanyDataWithoutUndefinedField = (
    companyName?: string,
    companyEmails?: string[],
    companyStateCode?: StateCode,
    companyCustomQuestions?: ICustomQuestion[],
    customIntroText?: string,
    customClearedText?: string,
    customNotClearedText?: string,
    customNotClearedEmailText?: string,
    noEmailPersist?: boolean,
    disableVaccinePassportQuestion?: boolean
  ): Partial<Company> => {
    const companyData: Partial<Company> = {};
    if (companyName) {
      companyData.companyName = companyName;
    }
    if (companyEmails) {
      companyData.companyEmails = companyEmails;
    }
    if (companyStateCode) {
      companyData.companyStateCode = companyStateCode;
    }
    if (companyCustomQuestions) {
      companyData.companyCustomQuestions = companyCustomQuestions;
    }
    if (customIntroText) {
      companyData.customIntroText = customIntroText;
    }
    if (customClearedText) {
      companyData.customClearedText = customClearedText;
    }
    if (customNotClearedText) {
      companyData.customNotClearedText = customNotClearedText;
    }
    if (customNotClearedEmailText) {
      companyData.customNotClearedEmailText = customNotClearedEmailText;
    }
    if (noEmailPersist !== undefined) {
      companyData.noEmailPersist = noEmailPersist;
    }
    if (disableVaccinePassportQuestion !== undefined) {
      companyData.disableVaccinePassportQuestion = disableVaccinePassportQuestion;
    }
    return companyData;
  };
}

class AuthService {
  getCurrentUser = (): firebase.User | null => {
    return auth.currentUser;
  };

  isSignedIn = (): boolean => {
    return !!auth.currentUser;
  };

  signOut = (): void => {
    auth.signOut();
  };

  signInWithEmailAndPassword = (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  signUpWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  sendResetPasswordEmail = (email: string): Promise<void> => {
    return auth.sendPasswordResetEmail(email, {
      url: `https://simplyscreen.health${NavigationRoute.ManagerPortal}`,
    });
  };

  onAuthStateChanged = (
    callback: (user: firebase.User | null) => void
  ): void => {
    auth.onAuthStateChanged(callback);
  };
}

class EmailService {
  private sendEmailGenerator = () =>
    cloudFunctions.httpsCallable("emailResults");

  private sendFailedResultsToEmployerGenerator = () =>
    cloudFunctions.httpsCallable("failedScreening");

  private sendRedAlertToEmployeesGenerator = () =>
    cloudFunctions.httpsCallable("sendRedAlertEmail");

  sendResultsToSelf = async (
    email: string,
    name: string,
    submissionDate: Date,
    isCleared: boolean
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const send = this.sendEmailGenerator();
    return send({
      email,
      name,
      submissionDate: submissionDate.toString(),
      isCleared,
    });
  };

  sendFailedResultsToEmployer = async (
    employerEmails: string[],
    employeeEmail: string,
    employeeName: string,
    employeeSubmissionDate: Date,
    companyId: string
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const send = this.sendFailedResultsToEmployerGenerator();
    return send({
      employerEmails,
      employeeEmail,
      employeeName,
      employeeSubmissionDate: employeeSubmissionDate.toString(),
      companyId,
    });
  };

  sendRedAlertToEmployees = async (
    employeeEmails: string[],
    emailMessage: string
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const send = this.sendRedAlertToEmployeesGenerator();
    return send({
      employeeEmails,
      emailMessage,
    });
  };
}

class PaymentService {
  private createCheckoutSessionGenerator = () =>
    cloudFunctions.httpsCallable("createCheckoutSession");

  private createCustomerPortalGenerator = () =>
    cloudFunctions.httpsCallable("createCustomerPortalSession");

  createCheckoutSession = async (
    priceId: string
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const send = this.createCheckoutSessionGenerator();
    return send({ priceId });
  };

  createCustomerPortal = async (): Promise<firebase.functions.HttpsCallableResult> => {
    const send = this.createCustomerPortalGenerator();
    return send();
  };
}

export default new FirestoreService();
export const authService = new AuthService();
export const emailService = new EmailService();
export const paymentService = new PaymentService();
