import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import { themeColors } from "../../styles";
import { Hidden, Typography } from "@material-ui/core";

const SIMPLY_SCREEN_PHONE_NUMBER = "16574751861";
const SIMPLY_SCREEN_PHONE_NUMBER_FORMATTED = "(657) 475-1861";

interface IPhoneNumberContactProps {
  style?: React.CSSProperties;
}

export const PhoneNumberContact: React.FunctionComponent<IPhoneNumberContactProps> = (
  props
) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <Hidden xsDown>
        <Typography variant="caption" style={{ marginRight: "0.5rem" }}>
          {"Have a question? Call us"}
        </Typography>
      </Hidden>
      <a
        href={`tel:${SIMPLY_SCREEN_PHONE_NUMBER}`}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: themeColors.lightGray,
          ...props.style,
        }}
      >
        <PhoneIcon style={{ marginRight: "3px" }} />
        {SIMPLY_SCREEN_PHONE_NUMBER_FORMATTED}
      </a>
    </div>
  );
};
