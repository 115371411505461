import { Dialog, DialogTitle, Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { authService } from "../../services/firestore";
import UIUtils from "../../uiUtils";

interface IResetPasswordDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export const ResetPasswordDialog: React.FunctionComponent<IResetPasswordDialogProps> = (
  props
) => {
  const [email, setEmail] = useState<string>("");
  const handleSendPasswordResetEmail = () => {
    if (!email) {
      UIUtils.showSnackbar("Enter an email address");
      return;
    }
    authService
      .sendResetPasswordEmail(email)
      .then(() => {
        UIUtils.showSnackbar("Password reset email sent");
        props.closeDialog();
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          UIUtils.showSnackbar("Email badly formatted");
        } else if (error.code === "auth/user-not-found") {
          UIUtils.showSnackbar("We couldn't find an account with that email");
        } else {
          UIUtils.showSnackbar("Something went wrong");
        }
      });
  };
  const handleCancel = () => {
    props.closeDialog();
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  return (
    <Dialog open={props.isOpen}>
      <div
        style={{ padding: "1rem", display: "flex", flexDirection: "column" }}
      >
        <DialogTitle id={`password-reset-dialog-title`}>
          {"Reset your account password"}
        </DialogTitle>
        <TextField
          id="reset-password-email-input"
          label="Enter your email address"
          onChange={handleEmailChange}
          value={email}
          variant="outlined"
          style={{ marginBottom: "2rem" }}
        />
        <Button
          style={{ margin: "0.5rem" }}
          variant="outlined"
          onClick={handleSendPasswordResetEmail}
        >
          {"Send password reset email"}
        </Button>
        <Button
          style={{ margin: "0.5rem" }}
          variant="outlined"
          onClick={handleCancel}
        >
          {"Cancel"}
        </Button>
      </div>
    </Dialog>
  );
};
