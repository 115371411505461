import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { authService } from "../../services/firestore";

interface IAdminLoginProps {}

export const AdminLogin: React.FunctionComponent<IAdminLoginProps> = (
  props
) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const onSignIn = () => {
    authService.signInWithEmailAndPassword(email, password);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        value={email}
        label={"Email"}
        type="email"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
        }}
      />
      <TextField
        value={password}
        label={"Password"}
        type="password"
        autoComplete="current-password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(event.target.value);
        }}
      />
      <Button variant="contained" color="primary" onClick={onSignIn}>
        Sign In as Admin
      </Button>
    </div>
  );
};
