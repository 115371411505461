import React from "react";
import { IVaccinatedMember } from "../../types";
import { Results } from "../questionnaire/Results";

interface IVaccineVerifiedProps {
  companyId: string;
  vaccinatedMember: IVaccinatedMember;
}

export const VaccineVerified: React.FunctionComponent<IVaccineVerifiedProps> = (
  props
) => {
  const { companyId } = props;
  const { firstName, lastName, email } = props.vaccinatedMember;
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Results
        companyId={companyId}
        isClear={true}
        isVaccinated={true}
        employeeFirstName={firstName}
        employeeLastName={lastName}
        employeeEmail={email}
      />
    </div>
  );
};
