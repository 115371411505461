import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { maxQuestionnaireWidth } from "../../../styles";
import { NewCompanyOnboardingStep, StateCode } from "../../../types";
import { DevErrorBox } from "../../common/DevErrorBox";
import { LogoHeader } from "../../common/LogoHeader";
import { PaperWrapper } from "../../common/PaperWrapper";
import { NewCompanyOnboardingManagerSignUp } from "./ManagerNewCompanySignUp";
import { NewCompanyOnboardingIntroduction } from "./NewCompanyOnboardingIntroduction";
import { NewCompanyOnboardingLogoUpload } from "./NewCompanyOnboardingLogoUpload";
import { NewCompanyOnboardingManagerEmails } from "./NewCompanyOnboardingManagerEmails";
import { NewCompanyOnboardingName } from "./NewCompanyOnboardingName";
import { NewCompanyOnboardingStateCode } from "./NewCompanyOnboardingStateCode";

interface IManagerNewCompanyOnboardingProps {}

const newCompanyOnboardingStepsOrder = [
  NewCompanyOnboardingStep.CompanyName,
  NewCompanyOnboardingStep.CompanyState,
  NewCompanyOnboardingStep.CompanyEmails,
  NewCompanyOnboardingStep.SignUpAuth,
];

export const ManagerNewCompanyOnboarding: React.FunctionComponent<IManagerNewCompanyOnboardingProps> = (
  props
) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyStateCode, setCompanyStateCode] = useState<StateCode>(
    StateCode.Unknown
  );
  const [companyManagerEmailList, setCompanyManagerEmailList] = useState<
    string[]
  >([]);
  const [companyLogo, setCompanyLogo] = useState<File>();
  const goToNextStep = (): void => {
    setCurrentStepIndex(currentStepIndex + 1);
  };
  const goBackStep = (): void => {
    setCurrentStepIndex(currentStepIndex - 1);
  };
  const currentStep = newCompanyOnboardingStepsOrder[currentStepIndex];
  let mainContentToRender: JSX.Element;
  let isNextButtonDisabled: boolean = false;
  let isBackButtonVisible: boolean = true;
  let isNextButtonVisible: boolean = true;
  let shouldStepSaySkip: boolean = false;
  switch (currentStep) {
    case NewCompanyOnboardingStep.Introduction:
      mainContentToRender = <NewCompanyOnboardingIntroduction />;
      isBackButtonVisible = false;
      break;
    case NewCompanyOnboardingStep.CompanyName:
      mainContentToRender = (
        <NewCompanyOnboardingName
          companyName={companyName}
          setCompanyName={setCompanyName}
        />
      );
      isNextButtonDisabled = !companyName;
      isBackButtonVisible = false;
      break;
    case NewCompanyOnboardingStep.CompanyState:
      mainContentToRender = (
        <NewCompanyOnboardingStateCode
          companyStateCode={companyStateCode}
          setCompanyStateCode={setCompanyStateCode}
        />
      );
      isNextButtonDisabled = companyStateCode === StateCode.Unknown;
      break;
    case NewCompanyOnboardingStep.CompanyEmails:
      mainContentToRender = (
        <NewCompanyOnboardingManagerEmails
          emailList={companyManagerEmailList}
          setEmailList={setCompanyManagerEmailList}
        />
      );
      isNextButtonDisabled = companyManagerEmailList.length === 0;
      break;
    case NewCompanyOnboardingStep.CompanyLogo:
      mainContentToRender = (
        <NewCompanyOnboardingLogoUpload setCompanyLogo={setCompanyLogo} />
      );
      shouldStepSaySkip = companyLogo === undefined;
      break;
    case NewCompanyOnboardingStep.SignUpAuth:
      mainContentToRender = (
        <NewCompanyOnboardingManagerSignUp
          companyName={companyName}
          companyEmails={companyManagerEmailList}
          companyStateCode={companyStateCode}
          companyLogo={companyLogo}
        />
      );
      isNextButtonVisible = false;
      break;
    default:
      mainContentToRender = (
        <DevErrorBox errorMessage={"Unrecognized onboarding step"} />
      );
      break;
  }
  return (
    <div>
      <LogoHeader />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: maxQuestionnaireWidth,
          margin: "auto",
        }}
      >
        <PaperWrapper>
          {mainContentToRender}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            {isBackButtonVisible ? (
              <Button onClick={goBackStep}>{"Back"}</Button>
            ) : (
              <div />
            )}
            {isNextButtonVisible ? (
              <Button
                onClick={goToNextStep}
                disabled={isNextButtonDisabled}
                variant="contained"
                color="primary"
                style={{}}
              >
                {shouldStepSaySkip ? "Skip" : "Next"}
              </Button>
            ) : (
              <div />
            )}
          </div>
        </PaperWrapper>
      </div>
    </div>
  );
};
