import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Button,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import React from "react";

interface IQuestionCheckmarkProps {
  questionText: string;
  answerOptions: string[];
  handleAnswer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNext: () => void;
  onBack: () => void;
  selectedAnswers: string[];
}

export const QuestionCheckmark: React.FunctionComponent<IQuestionCheckmarkProps> = (
  props
) => {
  return (
    <div>
      <Typography variant={"h5"} style={{ marginBottom: "1rem" }}>
        {props.questionText}
      </Typography>
      <FormControl component="fieldset">
        <FormHelperText>Select all that apply</FormHelperText>
        <FormGroup onChange={props.handleAnswer}>
          {props.answerOptions.map((option, index) => {
            const isChecked =
              props.selectedAnswers.filter((answer) => answer === option)
                .length > 0;
            return (
              <FormControlLabel
                key={`${option}${index}`}
                control={
                  <Checkbox color="primary" checked={isChecked} name={option} />
                }
                label={option}
              />
            );
          })}
        </FormGroup>
      </FormControl>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        <Button onClick={props.onBack}>{"Back"}</Button>
        <Button
          disabled={
            props.selectedAnswers ? props.selectedAnswers.length === 0 : true
          }
          variant="outlined"
          color="primary"
          onClick={props.onNext}
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};
