import {
  Button,
  Dialog,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { ICustomQuestion } from "../../types";
import { PaperWrapper } from "../common/PaperWrapper";
import { CovidQuestionFormat } from "../questionnaire/covidQuestions";
import UIUtils from "../../uiUtils";
import ClearIcon from "@material-ui/icons/Clear";

interface ICustomQuestionDialogProps {
  isOpen: boolean;
  setClose: () => void;
  onSubmit: (customQuestion: ICustomQuestion) => void;
}

export const CustomQuestionDialog: React.FunctionComponent<ICustomQuestionDialogProps> = (
  props
) => {
  const [questionText, setQuestionText] = useState<string>("");
  const [format, setFormat] = useState<CovidQuestionFormat>(
    CovidQuestionFormat.Radio
  );
  const [answerOptions, setAnswerOptions] = useState<string[]>([]);
  const [answerOptionInput, setAnswerOptionInput] = useState<string>("");
  const [isRequired, setIsRequired] = useState<boolean>(false);

  const handleQuestionTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuestionText(event.target.value);
  };
  const handleAnswerOptionInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAnswerOptionInput(event.target.value);
  };
  const handleAddAnswerOption = () => {
    if (answerOptionInput) {
      setAnswerOptions([...answerOptions, answerOptionInput]);
      setAnswerOptionInput("");
    }
  };
  const handleIsRequiredChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsRequired(event.target.checked);
  };
  const isValidCustomQ = (): boolean => {
    switch (format) {
      case CovidQuestionFormat.Radio:
        return !!questionText && answerOptions.length > 1;
      case CovidQuestionFormat.Freeform:
        return !!questionText && answerOptions.length === 0;
      default:
        return false;
    }
  };
  const onSubmitCustomQuestion = () => {
    if (isValidCustomQ()) {
      props.onSubmit({
        format,
        questionText,
        answerOptions,
        isRequired,
      });
      setQuestionText("");
      setAnswerOptions([]);
      setIsRequired(true);
      props.setClose();
    } else {
      UIUtils.showSnackbar("Improperly formatted question");
    }
  };
  const handleFormatChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    setFormat(event.target.value as CovidQuestionFormat);
    if (event.target.value === CovidQuestionFormat.Freeform) {
      setAnswerOptions([]);
    }
  };
  return (
    <Dialog open={props.isOpen}>
      <div style={{ padding: "1rem" }}>
        <PaperWrapper>
          <InputLabel id="state-code-selector-helper-label">
            {"Question type"}
          </InputLabel>
          <Select
            labelId="custom-q-dialog-label"
            id="custom-q-dialog"
            value={format}
            onChange={handleFormatChange}
          >
            <MenuItem value={CovidQuestionFormat.Radio}>
              {CovidQuestionFormat.Radio}
            </MenuItem>
            <MenuItem value={CovidQuestionFormat.Freeform}>
              {CovidQuestionFormat.Freeform}
            </MenuItem>
          </Select>
        </PaperWrapper>
        <PaperWrapper>
          <TextField
            id="custom-question-text"
            label="Question Text"
            onChange={handleQuestionTextChange}
            value={questionText}
          />
        </PaperWrapper>
        {format === CovidQuestionFormat.Radio && (
          <PaperWrapper>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                onChange={handleAnswerOptionInputChange}
                value={answerOptionInput}
              />
              <Button onClick={handleAddAnswerOption}>{"Add answer"}</Button>
            </div>
            {answerOptions.length > 0 && (
              <List dense={false}>
                {answerOptions.map((answerO, index) => {
                  const handleAnswerOptionsRemoval = () => {
                    const updatedAnswerOptions = answerOptions.filter(
                      (currentAnswerOption) => currentAnswerOption !== answerO
                    );
                    setAnswerOptions(updatedAnswerOptions);
                  };
                  return (
                    <ListItem key={`${answerO}${index}`}>
                      <ListItemText
                        style={{ color: "gray" }}
                        primary={answerO}
                      />
                      <ListItemSecondaryAction>
                        <Button onClick={handleAnswerOptionsRemoval}>
                          <ClearIcon />
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </PaperWrapper>
        )}
        <PaperWrapper>
          <FormControlLabel
            control={
              <Switch
                checked={isRequired}
                onChange={handleIsRequiredChange}
                name="isRequiredCheck"
                color="primary"
              />
            }
            label={isRequired ? "Requires an answer" : "Can be skipped"}
          />
        </PaperWrapper>
        <Button onClick={onSubmitCustomQuestion}>{"Add"}</Button>
        <Button onClick={props.setClose}>{"Close"}</Button>
      </div>
    </Dialog>
  );
};
