import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FirestoreService from "../../services/firestore";
import ImageUploader from "react-images-upload";
import { PaperWrapper } from "../common/PaperWrapper";
import { ICustomQuestion, StateCode } from "../../types";
import { CustomQuestionDialog } from "./CustomQuestionDialog";
import { StateCodeDropdown } from "../common/StateCodeDropdown";
import { EmailInputList } from "../common/EmailInputList";
import { PortalTabTitle } from "../manager/portal/PortalTabTitle";
import { SaveButton } from "../common/SaveButton";
import UIUtils from "../../uiUtils";
import ClearIcon from "@material-ui/icons/Clear";
import { CovidQuestionFormat } from "../questionnaire/covidQuestions";

interface IAdminNewCompanyProps {
  isEditMode?: boolean;
  companyId?: string;
}

export const AdminNewCompany: React.FunctionComponent<IAdminNewCompanyProps> = (
  props
) => {
  const [
    isCustomQuestionDialogOpen,
    setIsCustomQuestionDialogOpen,
  ] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [companyLogo, setCompanyLogo] = useState<File>();
  const [currentCompanyLogoPreview, setCurrentCompanyLogoPreview] = useState<
    string | undefined
  >();
  const [companyEmails, setCompanyEmails] = useState<string[]>([]);
  const [companyStateCode, setCompanyStateCode] = useState<StateCode>(
    StateCode.Unknown
  );
  const [companyCustomQuestions, setCompanyCustomQuestions] = useState<
    ICustomQuestion[]
  >([]);
  const [customIntroText, setCustomIntroText] = useState<string>("");
  const [customClearedText, setCustomClearedText] = useState<string>("");
  const [customNotClearedText, setCustomNotClearedText] = useState<string>("");
  const [
    disableVaccinePassportQuestion,
    setDisableVaccinePassportQuestion,
  ] = useState<boolean>(false);
  const [
    customNotClearedEmailText,
    setCustomNotClearedEmailText,
  ] = useState<string>("");
  const [
    isLoadingEditableCompanyInfo,
    setIsLoadingEditableCompanyInfo,
  ] = useState<boolean>(!!props.isEditMode);

  useEffect(() => {
    if (props.isEditMode && props.companyId) {
      FirestoreService.getCompany(props.companyId).then((companyInfo) => {
        setIsLoadingEditableCompanyInfo(false);
        if (companyInfo) {
          setCompanyName(companyInfo.companyName || "");
          setCurrentCompanyLogoPreview(companyInfo.companyLogoUrl || "");
          setCompanyEmails(companyInfo.companyEmails || []);
          setCompanyStateCode(
            companyInfo.companyStateCode || StateCode.Unknown
          );
          setCompanyCustomQuestions(companyInfo.companyCustomQuestions || []);
          setCustomIntroText(companyInfo.customIntroText || "");
          setCustomClearedText(companyInfo.customClearedText || "");
          setCustomNotClearedText(companyInfo.customNotClearedText || "");
          setCustomNotClearedEmailText(
            companyInfo.customNotClearedEmailText || ""
          );
          setDisableVaccinePassportQuestion(
            companyInfo.disableVaccinePassportQuestion || false
          );
        }
      });
    }
  }, [props.companyId, props.isEditMode]);

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };
  const handleAddCompanyCustomQuestion = (customQuestion: ICustomQuestion) => {
    setCompanyCustomQuestions([...companyCustomQuestions, customQuestion]);
  };
  const handleCompanyLogoChange = (files: File[]): void => {
    setCompanyLogo(files[0]);
  };
  const handleCustomIntroText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomIntroText(event.target.value);
  };
  const handleCustomClearedText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomClearedText(event.target.value);
  };
  const handleCustomNotClearedText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomNotClearedText(event.target.value);
  };
  const handleCustomNotClearedEmailText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomNotClearedEmailText(event.target.value);
  };
  const handleDisableVaccinePassportChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newState = e.target.checked;
    setDisableVaccinePassportQuestion(newState);
  };
  const handleSubmit = (): void => {
    if (!companyName) {
      UIUtils.showSnackbar("Company Name cannot be blank");
      return;
    }
    if (!companyStateCode) {
      UIUtils.showSnackbar("State Code cannot be blank");
      return;
    }
    if (companyEmails.length === 0) {
      UIUtils.showSnackbar(
        "There must be at least 1 manager to receive emails"
      );
      return;
    }
    if (props.isEditMode && props.companyId) {
      FirestoreService.updateCompany(
        props.companyId,
        companyName,
        companyEmails,
        companyStateCode,
        companyLogo,
        companyCustomQuestions,
        customIntroText,
        customClearedText,
        customNotClearedText,
        customNotClearedEmailText,
        undefined,
        disableVaccinePassportQuestion,
      ).then(() => {
        UIUtils.showSnackbar("Company data successfully updated");
      });
    } else {
      FirestoreService.addNewCompany(
        companyName,
        companyEmails,
        companyStateCode,
        companyLogo,
        companyCustomQuestions,
        customIntroText,
        customClearedText,
        customNotClearedText,
        customNotClearedEmailText
      ).then(() => {
        UIUtils.showSnackbar("Company Added");
      });
    }
  };

  // TODO - add locations per company, specify employerEmails per company
  if (isLoadingEditableCompanyInfo) {
    return <CircularProgress />;
  }

  const disableVaccinePassportCheckboxLabel = disableVaccinePassportQuestion
    ? "Disabled"
    : "Not Disabled";

  return (
    <form noValidate autoComplete="off">
      <PortalTabTitle
        title={"Edit Company Info"}
        subtitle={
          "Update basic company info and customize the screening questionnaire with your own text, questions, etc"
        }
      />
      <SaveButton
        onSave={handleSubmit}
        buttonLabel={props.isEditMode ? "Save Changes" : "Add New Company"}
        confirmationDialogLabel={"Save company changes?"}
        style={{
          position: "sticky",
          top: "4rem",
          zIndex: 2,
        }}
      />
      <PaperWrapper
        title={"Company Name"}
        helpTooltipText={"The name of your company."}
      >
        <TextField
          id="standard-basic"
          label="Enter your company name"
          onChange={handleCompanyNameChange}
          value={companyName}
          variant="outlined"
          style={{ display: "flex" }}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"State Code"}
        helpTooltipText={
          "The state your company operates out of. This ensures your screening process is compliant with state-level laws."
        }
      >
        <StateCodeDropdown
          currentStateCode={companyStateCode}
          onChange={setCompanyStateCode}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Manager Emails"}
        helpTooltipText={
          "The emails of your company's managers. These are who will receive urgent notifications about failed screenings."
        }
      >
        <EmailInputList
          emailList={companyEmails}
          onChange={setCompanyEmails}
          placeholder={"Enter manager email"}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Company Logo"}
        helpTooltipText={
          "The logo of your company. This will appear at the top of your screening questionnaire."
        }
      >
        {currentCompanyLogoPreview && (
          <img
            style={{ height: "2rem" }}
            src={currentCompanyLogoPreview}
            alt={"Currentcompanylogo"}
          />
        )}
        <ImageUploader
          withIcon={false}
          buttonText={"Upload company logo"}
          onChange={handleCompanyLogoChange}
          imgExtension={[".jpg", ".png"]}
          withPreview={true}
          maxFileSize={5242880}
          singleImage
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Disable Vaccine Passports"}
        helpTooltipText={
          "Disables the initial 'Are you vaccinated' question from your screening process. Instead defaults to the symptom screening."
        }
      >
        <FormControl component="fieldset">
          <FormGroup onChange={handleDisableVaccinePassportChange} row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={disableVaccinePassportQuestion}
                  name={disableVaccinePassportCheckboxLabel}
                />
              }
              labelPlacement="end"
              label={disableVaccinePassportCheckboxLabel}
            />
          </FormGroup>
        </FormControl>
      </PaperWrapper>
      <PaperWrapper
        title={"Questionnaire Introduction Text"}
        helpTooltipText={
          "The introductory paragraph of your screening questionnaire."
        }
      >
        <TextField
          id="customIntroTextField"
          label="Enter intro text"
          onChange={handleCustomIntroText}
          value={customIntroText}
          variant="outlined"
          style={{ display: "flex" }}
          multiline={true}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Questionnaire 'Cleared' Message"}
        helpTooltipText={
          "The message that appears when someone passes their screening."
        }
      >
        <TextField
          id="customClearedTextField"
          label="Enter 'cleared' message"
          onChange={handleCustomClearedText}
          value={customClearedText}
          variant="outlined"
          style={{ display: "flex" }}
          multiline={true}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Questionnaire 'Failed' Message"}
        helpTooltipText={
          "The message that appears when someone fails their screening."
        }
      >
        <TextField
          id="customNotClearedTextField"
          label="Enter 'failed' message"
          onChange={handleCustomNotClearedText}
          value={customNotClearedText}
          variant="outlined"
          style={{ display: "flex" }}
          multiline={true}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Email Notification 'Failed' Message"}
        helpTooltipText={
          "The message in an email sent to the manager when someone fails their screening."
        }
      >
        <TextField
          id="customNotClearedEmailField"
          label="Enter 'failed' email message"
          onChange={handleCustomNotClearedEmailText}
          value={customNotClearedEmailText}
          variant="outlined"
          style={{ display: "flex" }}
          multiline={true}
        />
      </PaperWrapper>
      <PaperWrapper
        title={"Custom Questions"}
        helpTooltipText={
          "The custom questions that appear during the screening questionnaire. Use these to ask anything you want, such as 'How are you enjoying working here?'"
        }
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            onClick={() => setIsCustomQuestionDialogOpen(true)}
            variant="contained"
            color="primary"
          >
            {"Add custom question"}
          </Button>
        </div>
        {companyCustomQuestions.length > 0 && (
          <List dense={false}>
            {companyCustomQuestions.map((customQ, index) => {
              const handleCustomQuestionRemoval = () => {
                const updatedCompanyCustomQuestions = companyCustomQuestions.filter(
                  (filteredCustomQ) =>
                    filteredCustomQ.questionText !== customQ.questionText
                );
                setCompanyCustomQuestions(updatedCompanyCustomQuestions);
              };
              return (
                <ListItem key={`${customQ.questionText}${index}`}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <PaperWrapper>
                      <ListItemText
                        style={{ fontWeight: "bold" }}
                        primary={customQ.questionText}
                      />

                      {customQ.format === CovidQuestionFormat.Radio &&
                        customQ.answerOptions.map((answerO, index) => {
                          return (
                            <ListItemText
                              key={`${customQ.questionText}${answerO}${index}`}
                              style={{ color: "gray" }}
                              primary={answerO}
                            />
                          );
                        })}
                    </PaperWrapper>
                    <Typography variant="caption">
                      {customQ.isRequired
                        ? "This question is REQUIRED"
                        : "This question is OPTIONAL"}
                    </Typography>
                  </div>
                  <ListItemSecondaryAction>
                    <Button onClick={handleCustomQuestionRemoval}>
                      <ClearIcon />
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        )}
      </PaperWrapper>
      <CustomQuestionDialog
        isOpen={isCustomQuestionDialogOpen}
        onSubmit={handleAddCompanyCustomQuestion}
        setClose={() => setIsCustomQuestionDialogOpen(false)}
      />
    </form>
  );
};
