import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { themeColors } from "../../../styles";
import { ICompanyResultsOverTime } from "../../../types";
import { extractTodayResultsFromCompanyResults } from "../../../utils";
import { PaperWrapper } from "../../common/PaperWrapper";

interface IPortalHomeTodayResultsProps {
  questionnaireResults: ICompanyResultsOverTime | undefined;
  fetchAndSetQuestionnaireResults: () => void;
  goToScreeningResultsTab: () => void;
}

export const PortalHomeTodayResults: React.FunctionComponent<IPortalHomeTodayResultsProps> = (
  props
) => {
  useEffect(() => {
    if (!props.questionnaireResults) {
      props.fetchAndSetQuestionnaireResults();
    }
  });
  if (!props.questionnaireResults) {
    return <CircularProgress />;
  }

  const todayResults = extractTodayResultsFromCompanyResults(
    props.questionnaireResults
  );

  const renderCount = (
    text: string,
    count: number,
    color?: string
  ): JSX.Element => {
    return (
      <Grid
        item={true}
        xs={4}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography style={{ color: themeColors.textGray }}>{text}</Typography>
        <Typography variant="h4" style={{ color }}>
          {count}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Today's screening results</Typography>
        <Button
          variant="outlined"
          size="small"
          style={{ alignSelf: "flex-end" }}
          onClick={props.goToScreeningResultsTab}
        >
          {"Details"}
        </Button>
      </div>
      <PaperWrapper style={{ display: "flex" }}>
        {todayResults.totalCount === 0 ? (
          <Typography style={{ color: themeColors.textGray }}>
            {"No screening results collected today"}
          </Typography>
        ) : (
          <>
            <Grid container={true}>
              {renderCount("Total", todayResults.totalCount)}
              {renderCount(
                "Cleared",
                todayResults.clearedCount,
                themeColors.greenSuccess
              )}
              {renderCount(
                "Failed",
                todayResults.failedCount,
                themeColors.redAlert
              )}
            </Grid>
          </>
        )}
      </PaperWrapper>
    </>
  );
};
