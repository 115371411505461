import { Paper, Typography } from "@material-ui/core";
import React from "react";
import { HelpTooltip } from "./HelpTooltip";

interface IPaperWrapperProps {
  style?: React.CSSProperties;
  title?: string;
  helpTooltipText?: string;
}

export const PaperWrapper: React.FunctionComponent<IPaperWrapperProps> = (
  props
) => {
  return (
    <Paper
      style={{
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem",
        ...props.style,
      }}
      elevation={5}
    >
      {(props.title || props.helpTooltipText) && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          {props.title ? <Typography>{props.title}</Typography> : <div />}
          {props.helpTooltipText ? (
            <HelpTooltip tooltipText={props.helpTooltipText} />
          ) : (
            <div />
          )}
        </div>
      )}
      {props.children}
    </Paper>
  );
};
