import { TextField, Typography } from "@material-ui/core";
import React from "react";

interface INewCompanyOnboardingNameProps {
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
}

export const NewCompanyOnboardingName: React.FunctionComponent<INewCompanyOnboardingNameProps> = (
  props
) => {
  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.setCompanyName(event.target.value);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "In just a quick few steps, you'll have an end-to-end solution for COVID-19 screening and contact tracing. We'll ask a few questions to tailor your screening solution uniquely for you. It should only take a minute or two."
        }
      </Typography>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        {"What is your company's name?"}
      </Typography>
      <TextField
        id="new-company-onboarding-company-name-input"
        label="Company Name"
        variant="outlined"
        onChange={handleCompanyNameChange}
        value={props.companyName}
      />
    </div>
  );
};
