import { Typography, Grid } from "@material-ui/core";
import React from "react";
import { themeColors } from "../../../styles";
import { PortalHomeDistributeQuestionnaireItem } from "./PortalHomeDistributeQuestionnaireItem";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import LinkIcon from "@material-ui/icons/Link";
import UIUtils from "../../../uiUtils";
import { generateQuestionnaireFullLink } from "../../../utils";
//@ts-ignore
const QRCode = require("qrcode.react");

interface IPortalHomeDistributeQuestionnaireProps {
  companyId: string;
  companyName: string;
  goToEmailRemindersTab: () => void;
}

export const PortalHomeDistributeQuestionnaire: React.FunctionComponent<IPortalHomeDistributeQuestionnaireProps> = (
  props
) => {
  const questionnaireLink = generateQuestionnaireFullLink(props.companyId);
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(questionnaireLink);
    UIUtils.showSnackbar("Link copied");
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById("questionnaireQRCode");
    if (canvas) {
      const pngUrl = (canvas as any)
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${props.companyName} Screening QR Code`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: "1rem" }}>
        Distribute your screening questionnaire
      </Typography>
      <Grid container={true} spacing={1}>
        <Grid item={true} md={4} xs={12}>
          <PortalHomeDistributeQuestionnaireItem
            color={themeColors.secondary}
            iconElement={<LinkIcon />}
            description={"Share a weblink via email, text message, etc."}
            actionText={"Copy Link"}
            onClick={copyLinkToClipboard}
          />
        </Grid>
        <Grid item={true} md={4} xs={12}>
          <PortalHomeDistributeQuestionnaireItem
            color={themeColors.tertiary}
            iconElement={<FilterCenterFocusIcon />}
            description={
              "Download a QR code that anybody can scan at your front door."
            }
            actionText={"Download QR"}
            onClick={downloadQRCode}
          />
        </Grid>
        <Grid item={true} md={4} xs={12}>
          <PortalHomeDistributeQuestionnaireItem
            color={themeColors.primary}
            iconElement={<MailOutlineIcon />}
            description={"Enter emails that'll receive daily reminders."}
            actionText={"Enter Emails"}
            onClick={props.goToEmailRemindersTab}
          />
        </Grid>
      </Grid>
      <QRCode
        style={{ display: "none" }}
        id={"questionnaireQRCode"}
        value={questionnaireLink}
      />
    </>
  );
};
