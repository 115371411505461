import _ from "lodash";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ICompanyDailyEmailReminders } from "../../../types";
import { EmailInputList } from "../../common/EmailInputList";
import { PaperWrapper } from "../../common/PaperWrapper";
import { SaveButton } from "../../common/SaveButton";
import { PortalTabTitle } from "./PortalTabTitle";
import {
  offsetTimeArrayIntoLocalTimezone,
  offsetTimeArrayIntoUTCTimezone,
  transposeTimeArrayToTimeString,
  transposeTimeStringToTimeArray,
  transposeWeekdaysToNumbers,
} from "../../../utils";
import FirestoreService from "../../../services/firestore";
import UIUtils from "../../../uiUtils";

interface IPortalEmailRemindersProps {
  companyId: string;
  noEmailPersist: boolean;
  dailyEmailReminderData: ICompanyDailyEmailReminders | undefined;
  fetchAndSetDailyEmailReminderData: () => void;
  setDailyEmailReminderData: React.Dispatch<
    React.SetStateAction<ICompanyDailyEmailReminders | undefined>
  >;
}

export const PortalEmailReminders: React.FunctionComponent<IPortalEmailRemindersProps> = (
  props
) => {
  const [noEmailPersist, setNoEmailPersist] = useState<boolean>(
    props.noEmailPersist
  );
  const dailyEmailReminderData = props.dailyEmailReminderData;
  const fetchAndSetDataFxn = props.fetchAndSetDailyEmailReminderData;
  useEffect(() => {
    if (!dailyEmailReminderData) {
      fetchAndSetDataFxn();
    }
  }, [dailyEmailReminderData, fetchAndSetDataFxn]);
  if (!dailyEmailReminderData) {
    return <CircularProgress />;
  }

  const onSaveReminderData = async (): Promise<void> => {
    if (dailyEmailReminderData) {
      try {
        FirestoreService.saveCompanyEmailReminders(
          props.companyId,
          dailyEmailReminderData
        );
      } catch (error) {
        UIUtils.showSnackbar(
          "Ooops, something went wrong. Refresh and try again."
        );
      }
    }

    if (noEmailPersist !== props.noEmailPersist) {
      try {
        FirestoreService.updateCompany(
          props.companyId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          noEmailPersist
        );
      } catch (error) {
        UIUtils.showSnackbar(
          "Ooops, something went wrong. Refresh and try again."
        );
      }
    }

    UIUtils.showSnackbar("Email reminder info updated");
  };
  const handleNoEmailPersistChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const newState = e.target.checked;
    setNoEmailPersist(newState);
  };
  const handleWeekdayChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const weekday = e.target.name;
    const newIsCheckedState = e.target.checked;
    const updatedData = _.cloneDeep(dailyEmailReminderData);
    if (updatedData) {
      if (newIsCheckedState) {
        updatedData.notificationsList[0].days.push(
          transposeWeekdaysToNumbers(weekday)
        );
      } else {
        const newDays = updatedData.notificationsList[0].days.filter(
          (day) => day !== transposeWeekdaysToNumbers(weekday)
        );
        updatedData.notificationsList[0].days = newDays;
      }
      props.setDailyEmailReminderData(updatedData);
    }
  };
  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedData = _.cloneDeep(dailyEmailReminderData);
    if (updatedData) {
      updatedData.notificationsList[0].time = offsetTimeArrayIntoUTCTimezone(
        transposeTimeStringToTimeArray(event.target.value)
      );
      props.setDailyEmailReminderData(updatedData);
    }
  };
  const handleContactListChange = (newContactList: string[]) => {
    const updatedData = _.cloneDeep(dailyEmailReminderData);
    if (updatedData) {
      updatedData.employeeEmails = newContactList;
      props.setDailyEmailReminderData(updatedData);
    }
  };
  const isWeekdayChecked = (weekday: string): boolean => {
    if (dailyEmailReminderData) {
      return dailyEmailReminderData?.notificationsList[0].days.includes(
        transposeWeekdaysToNumbers(weekday)
      );
    } else {
      return false;
    }
  };
  const renderWeekdayCheckbox = (weekday: string): JSX.Element => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={isWeekdayChecked(weekday)}
            name={weekday}
          />
        }
        labelPlacement="bottom"
        label={weekday}
      />
    );
  };

  const noEmailPersistCheckboxLabel = noEmailPersist ? "Yes" : "No";

  return (
    <React.Fragment>
      <PortalTabTitle
        title={"Manage Email Reminders"}
        subtitle={
          "Configure what days & times people receive a reminder to complete their screening, and who gets them"
        }
      />
      <SaveButton
        onSave={onSaveReminderData}
        buttonLabel={"Save Changes"}
        confirmationDialogLabel={"Save email reminder changes?"}
        style={{
          position: "sticky",
          top: "4rem",
          zIndex: 2,
        }}
      />
      <PaperWrapper>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {"When should screening reminder emails be sent?"}
        </Typography>
        <FormControl component="fieldset">
          <FormGroup onChange={handleWeekdayChange} row={true}>
            {renderWeekdayCheckbox("Monday")}
            {renderWeekdayCheckbox("Tuesday")}
            {renderWeekdayCheckbox("Wednesday")}
            {renderWeekdayCheckbox("Thursday")}
            {renderWeekdayCheckbox("Friday")}
            {renderWeekdayCheckbox("Saturday")}
            {renderWeekdayCheckbox("Sunday")}
          </FormGroup>
        </FormControl>
        <form noValidate style={{ marginTop: "1rem" }}>
          <TextField
            id="time"
            type="time"
            defaultValue={transposeTimeArrayToTimeString(
              offsetTimeArrayIntoLocalTimezone(
                dailyEmailReminderData.notificationsList[0].time
              )
            )}
            onChange={handleTimeChange}
            inputProps={{
              step: 300, // 5 min intervals when using keyboard arrows
            }}
          />
        </form>
        <Typography variant="caption">
          {`(Timezone: ${
            window.Intl.DateTimeFormat().resolvedOptions().timeZone
          })`}
        </Typography>
      </PaperWrapper>
      <PaperWrapper>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {"Who should receive screening reminder emails?"}
        </Typography>
        <EmailInputList
          emailList={dailyEmailReminderData.employeeEmails}
          onChange={handleContactListChange}
          placeholder={"Enter email"}
          enableCsvImport={true}
        />
      </PaperWrapper>
      <PaperWrapper>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {
            "Disable automatic adding of emails to your notification list when users fill out questionnaire?"
          }
        </Typography>
        <FormControl component="fieldset">
          <FormGroup onChange={handleNoEmailPersistChange} row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={noEmailPersist}
                  name={noEmailPersistCheckboxLabel}
                />
              }
              labelPlacement="bottom"
              label={noEmailPersistCheckboxLabel}
            />
          </FormGroup>
        </FormControl>
      </PaperWrapper>
    </React.Fragment>
  );
};
