import { ICovidQuestion } from "./components/questionnaire/covidQuestions";

export enum NavigationRoute {
  AdminHome = "/admin",
  AdminNewCompany = "/adminNewCompany",
  AdminCompaniesList = "/adminCompaniesList",
  AdminEditCompany = "/adminEditCompany/:companyId",
  AdminCsvExport = "/adminCsvExport/:companyId/:companyName",
  AdminGodPortal = "/adminGodPortal/:companyId",
  Questionnaire = `/questionnaire/:companyId`,
  VaccineVerification = `/vaccineVerification/:companyId`,
  ManagerSignInSignUp = "/signInSignUp",
  ManagerNewCompanyOnboarding = "/newCompanyOnboarding",
  ManagerPortal = "/portal",
  PaymentSuccess = "/billing/success",
}

export interface Company {
  id: string;
  companyName: string;
  companyEmails: string[];
  companyStateCode: StateCode;
  createdAt: Date;
  companyLogoUrl?: string;
  companyCustomQuestions?: ICustomQuestion[];
  customIntroText?: string;
  customClearedText?: string;
  customNotClearedText?: string;
  customNotClearedEmailText?: string;
  noEmailPersist?: boolean;
  disableVaccinePassportQuestion?: boolean;
  stripeCustomerId?: string
}

export interface IQuestionnaireResults {
  isClear?: boolean;
  isVaccinated?: boolean;
  submissionDate?: Date;
  severityMessage?: IQuestionnaireResultSeverityMessage;
}

export enum IQuestionnaireResultSeverityMessage {
  Clear = "Clear",
  Symptomatic = "Symptomatic", // Stay at home. Follow CDC guidance for further steps.
  Asymptomatic = "Asymptomatic", // Stay at home and monitor for symptoms. If you develop symptoms, follow CDC guidance.
}

export interface ICompanyResultsOverTime {
  [date: string]: {
    resultsCount: number;
    results: ICompanyResultPerEmployee[];
  };
}

export interface ICustomQuestion extends ICovidQuestion {
  isRequired: boolean;
}

export enum StateCode {
  Unknown = "Unknown",
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UM = "UM",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum NewCompanyOnboardingStep {
  Introduction,
  CompanyName,
  CompanyState,
  CompanyEmails,
  CompanyLogo,
  SignUpAuth,
}

export enum PortalTabType {
  Home = "Home",
  ScreeningResults = "Screening Results",
  EmailReminders = "Email Reminders",
  EditCompanyInfo = "Edit Company Info",
  AlertCovidCase = "Report a positive COVID case",
  VaccinePassports = "Manage Vaccine Passports"
}

export interface ICompanyResultPerEmployee {
  firstName: string;
  lastName: string;
  email: string;
  isCleared: boolean;
  customQuestionAnswerPairs: {
    [questionText: string]: string;
  };
}

export interface ICompanyDailyEmailReminders {
  employeeEmails: string[];
  notificationsList: [
    {
      lastSent: Date;
      worker: string;
      days: number[];
      time: number[];
    }
  ];
}

export interface TableRowData extends ICompanyResultPerEmployee {
  [key: string]: any;
  id: string;
  date: string;
}

export interface ICompanyResultsTableRows {
  headerRow: { name: string; key: string; custom?: boolean }[];
  tableRows: TableRowData[];
}

export interface IVaccinatedMember {
    firstName: string;
    lastName: string;
    email: string;
    pinCode: string;
}
