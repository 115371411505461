import { Button, Typography } from "@material-ui/core";
import React from "react";
import { themeColors, useBreakpointStyles } from "../../../styles";
import { PaperWrapper } from "../../common/PaperWrapper";

interface IPortalHomeDistributeQuestionnaireItemProps {
  color: string;
  iconElement: JSX.Element;
  description: string;
  actionText: string;
  onClick: () => void;
}

export const PortalHomeDistributeQuestionnaireItem: React.FunctionComponent<IPortalHomeDistributeQuestionnaireItemProps> = (
  props
) => {
  const breakpointStyles = useBreakpointStyles();
  return (
    <PaperWrapper style={{ margin: 0 }}>
      <Typography
        className={breakpointStyles.breakpointMinHeight5IfAboveSm}
        style={{
          color: themeColors.textGray,
          textAlign: "center",
        }}
      >
        {props.description}
      </Typography>
      <Button
        style={{ backgroundColor: props.color, color: "white" }}
        onClick={props.onClick}
        fullWidth={true}
        startIcon={props.iconElement}
      >
        <Typography>{props.actionText}</Typography>
      </Button>
    </PaperWrapper>
  );
};
