import { Grid } from "@material-ui/core";
import React from "react";
import { PortalTabTitle } from "./PortalTabTitle";
import { PortalHomeTodayResults } from "./PortalHomeTodayResults";
import { ICompanyResultsOverTime } from "../../../types";
import { PortalHomeDistributeQuestionnaire } from "./PortalHomeDistributeQuestionnaire";
import { PortalHomeCovidCase } from "./PortalHomeCovidCase";

interface IPortalHomeProps {
  companyId: string;
  companyName: string;
  goToEmailRemindersTab: () => void;
  questionnaireResults: ICompanyResultsOverTime | undefined;
  fetchAndSetQuestionnaireResults: () => void;
  goToScreeningResultsTab: () => void;
  goToAlertCovidCaseTab: () => void;
}

export const PortalHome: React.FunctionComponent<IPortalHomeProps> = (
  props
) => {
  return (
    <React.Fragment>
      <PortalTabTitle title={`${props.companyName} Dashboard`} />
      <Grid container={true} spacing={5}>
        <Grid item={true} xs={12}>
          <PortalHomeDistributeQuestionnaire
            companyId={props.companyId}
            companyName={props.companyName}
            goToEmailRemindersTab={props.goToEmailRemindersTab}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <PortalHomeTodayResults
            questionnaireResults={props.questionnaireResults}
            fetchAndSetQuestionnaireResults={
              props.fetchAndSetQuestionnaireResults
            }
            goToScreeningResultsTab={props.goToScreeningResultsTab}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <PortalHomeCovidCase
            goToAlertCovidCaseTab={props.goToAlertCovidCaseTab}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

// const GettingStartedListItem: React.FunctionComponent<{
//   primaryText: string;
//   secondaryText: string;
// }> = (props) => {
//   return (
//     <ListItem>
//       <ListItemIcon>
//         <CheckIcon />
//       </ListItemIcon>
//       <ListItemText
//         primary={props.primaryText}
//         secondary={props.secondaryText}
//       />
//     </ListItem>
//   );
// };

/* <List dense={false}>
  <GettingStartedListItem
    primaryText={"What do I do if someone fails their screening?"}
    secondaryText={
      "Make sure those who fail their screening stay home. An automatic email will be sent to all managers listed in 'MANAGER EMAILS' which can be found in 'EDIT COMPANY INFO'. This email will contain the person's contact info for you to follow up with next steps. Use this CDC resource for guidance: https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/index.html"
    }
  />
  <GettingStartedListItem
    primaryText={
      "How do people receive a reminder to complete their screening?"
    }
    secondaryText={
      "Send the screening questionnaire link to someone who will enter the workspace and have them complete it once: that person's email will be automatically saved. An email will be sent to them on the days/times that you specify in the 'EMAIL REMINDERS' tab. You can also manually add/remove people from these reminders in the 'EMAIL REMINDERS' tab."
    }
  />
  <GettingStartedListItem
    primaryText={
      "What do I do if someone reports that they are COVID positive?"
    }
    secondaryText={
      "Go to the 'REPORT A POSITIVE COVID CASE' tab. You can send an email blast out to all people who entered the workspace on the same day as them in the past 14 days."
    }
  />
  <GettingStartedListItem
    primaryText={
      "How often should people fill out the screening before entering the workspace?"
    }
    secondaryText={
      "A screening result is valid for 24 hours. A person only needs to complete their screening once per day before entering the workspace."
    }
  />
  <GettingStartedListItem
    primaryText={
      "What if somebody passes their screening but then develops symptoms while in the workspace?"
    }
    secondaryText={
      "The person should immediately go home if they feel or appear sick in the workspace. Cordon off any areas where somebody with probable or confirmed COVID-19 illness worked, touched surfaces, etc. until the area and equipment is cleaned and sanitized."
    }
  />
</List>; */
