import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CheckboxesTealSvg from "../../client-assets/checkboxes-teal.svg";
import { EmployeeInfoVaccineQuestion } from "./EmployeeInfoVaccineQuestion";
import { EmployeeInfoEntry } from "./EmployeeInfoEntry";
import { useLocation } from "react-router";
import { SKIP_VACCINE_QUESTION_KEY } from "../../constants";

interface IEmployeeInfoProps {
  companyId: string;
  companyName: string;
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmail: string;
  handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasAttemptedSubmit: boolean;
  onStart: () => void;
  customIntroText?: string;
  disableVaccinePassport?: boolean;
}

export const EmployeeInfo: React.FunctionComponent<IEmployeeInfoProps> = (
  props
) => {
  const currLocation = useLocation();
  const shouldSkipVaccineQuestion = !!new URLSearchParams(
    currLocation.search
  ).get(SKIP_VACCINE_QUESTION_KEY);
  const [isVaccineQuestion, setIsVaccineQuestion] = useState<boolean>(true);
  const showVaccineQuestion = isVaccineQuestion && !shouldSkipVaccineQuestion && !props.disableVaccinePassport;
  const introductoryText =
    props.customIntroText ||
    `Help ${props.companyName} maintain a safe work environment by completing this questionnaire everyday before entering the workplace.`;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <img
          alt={"Covid-Questionnaire"}
          src={CheckboxesTealSvg}
          style={{ height: "10rem" }}
        />
        <Typography variant="h5" style={{ marginTop: "1rem" }}>
          {`COVID-19 Screening Questionnaire`}
        </Typography>
        <Typography variant="subtitle2">{introductoryText}</Typography>
      </div>
      {showVaccineQuestion ? (
        <EmployeeInfoVaccineQuestion
          companyId={props.companyId}
          goToEmployeeInfoEntryPhase={() => setIsVaccineQuestion(false)}
        />
      ) : (
        <EmployeeInfoEntry
          employeeFirstName={props.employeeFirstName}
          employeeLastName={props.employeeLastName}
          employeeEmail={props.employeeEmail}
          handleFirstNameChange={props.handleFirstNameChange}
          handleLastNameChange={props.handleLastNameChange}
          handleEmailChange={props.handleEmailChange}
          hasAttemptedSubmit={props.hasAttemptedSubmit}
          onStart={props.onStart}
        />
      )}
    </div>
  );
};
