import { Typography } from "@material-ui/core";
import React from "react";

interface INewCompanyOnboardingIntroductionProps {}

export const NewCompanyOnboardingIntroduction: React.FunctionComponent<INewCompanyOnboardingIntroductionProps> = (
  props
) => {
  return (
    <div>
      <Typography>
        {
          "In just a quick few steps, you'll have an end-to-end solution for COVID-19 screening and contact tracing. We'll ask a few questions to tailor your screening solution uniquely for you. It should only take a minute or two."
        }
      </Typography>
    </div>
  );
};
