import React from "react";
import { Button, TextField } from "@material-ui/core";

interface IEmployeeInfoEntryProps {
  employeeFirstName: string;
  employeeLastName: string;
  employeeEmail: string;
  handleFirstNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasAttemptedSubmit: boolean;
  onStart: () => void;
}

export const EmployeeInfoEntry: React.FunctionComponent<IEmployeeInfoEntryProps> = (
  props
) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "1rem",
      }}
    >
      <TextField
        id="employee-first-name-input"
        label="First name"
        onChange={props.handleFirstNameChange}
        value={props.employeeFirstName}
        error={props.hasAttemptedSubmit && !props.employeeFirstName}
        required={true}
      />
      <TextField
        style={{ marginTop: "1rem" }}
        id="employee-last-name-input"
        label="Last name"
        onChange={props.handleLastNameChange}
        value={props.employeeLastName}
        error={props.hasAttemptedSubmit && !props.employeeLastName}
        required={true}
      />
      <TextField
        style={{ marginTop: "1rem" }}
        id="employee-email-input"
        label="Email"
        onChange={props.handleEmailChange}
        value={props.employeeEmail}
        error={props.hasAttemptedSubmit && !props.employeeEmail}
        required={true}
      />
      <Button
        style={{ marginTop: "2rem" }}
        variant="contained"
        color="primary"
        onClick={props.onStart}
      >
        {"Start symptom check"}
      </Button>
    </div>
  );
};
