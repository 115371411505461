export enum CovidQuestionId {
  LifeThreateningSymptoms = "LifeThreateningSymptoms",
  FeelingSick = "FeelingSick",
  CloseContactAsymp = "CloseContactAsymp",
  LiveInFacility = "LiveInFacility",
  HospitalWorker = "HospitalWorker",
  HospitalWorkerPPE = "HospitalWorkerPPE",
  CloseContactSympto = "CloseContactSympto",
  CovidSymptoms = "CovidSymptoms",
  TestedPositive = "TestedPositive",
  StateTravelRisk = "StateTravelRisk",
  VaccineGeneral = "VaccineGeneral",
}

export enum CovidQuestionFormat {
  Radio = "Radio",
  Checkmark = "Checkmark",
  Freeform = "Freeform",
}

// use CovidQuestionId enum as the key
export interface IAnswers {
  [covidQuestionId: string]: string[];
}

// Variables used to determine medical algorithm flow down the line
export interface IAnswerVariables {
  isOver65?: boolean;
  isAsymptomatic?: boolean;
}

export interface ICovidQuestion {
  format: CovidQuestionFormat;
  questionText: string;
  answerOptions: string[];
  questionBulletpoints?: string[];
}

export interface ICovidSymptomChecklistAnalysis {
  noSymptoms: boolean;
  onlyOther: boolean;
  onlySecondary: boolean;
  anyPrimary: boolean;
  primary1: boolean;
  primary2plus: boolean;
}

export const defaultQuestionnaireResetHours = 23;

// P - "Fever or feeling feverish (such as chills, sweating)",
// P - "Cough",
// P - "Mild or moderate difficulty breathing",
// S - "Sore throat",
// S - "Muscle aches or body aches",
// S - "Vomiting or diarrhea",
// S - "New loss of taste or smell",
// Nothing? - "Congestion or runny nose",
// O - "Other symptoms",
// N - "None of the above"
export const covidSymptomsChecklistAnalyzer = (
  symptoms: string[]
): ICovidSymptomChecklistAnalysis => {
  let analysis: ICovidSymptomChecklistAnalysis = {
    noSymptoms: true,
    onlyOther: false,
    onlySecondary: false,
    anyPrimary: false,
    primary1: false,
    primary2plus: false,
  };
  const containsNoneOfTheAbove = symptoms.includes("None of the above");
  const containsOther = symptoms.includes("Other symptoms");
  const secondaryCount = symptoms.filter(isSymptomSecondary).length;
  const primaryCount = symptoms.filter(isSymptomPrimary).length;

  if (containsNoneOfTheAbove && secondaryCount === 0 && primaryCount === 0) {
    // Use default analysis
  } else if (containsOther && secondaryCount === 0 && primaryCount === 0) {
    analysis.noSymptoms = false;
    analysis.onlyOther = true;
  } else if (secondaryCount > 0 && primaryCount === 0) {
    analysis.noSymptoms = false;
    analysis.onlySecondary = true;
  } else if (primaryCount > 0) {
    analysis.noSymptoms = false;
    analysis.anyPrimary = true;
    if (primaryCount === 1) {
      analysis.primary1 = true;
    } else if (primaryCount > 1) {
      analysis.primary2plus = true;
    }
  }
  return analysis;
};

const isSymptomPrimary = (symptom: string): boolean => {
  switch (symptom) {
    case "Fever or feeling feverish (such as chills, sweating)":
    case "Cough":
    case "Mild or moderate difficulty breathing":
      return true;
    default:
      return false;
  }
};

const isSymptomSecondary = (symptom: string): boolean => {
  switch (symptom) {
    case "Sore throat":
    case "Muscle aches or body aches":
    case "Vomiting or diarrhea":
    case "New loss of taste or smell":
      return true;
    default:
      return false;
  }
};

export const isQuestionIdCustom = (
  questionId: CovidQuestionId | string
): boolean => {
  return !Object.values(CovidQuestionId).includes(
    questionId as CovidQuestionId
  );
};

export const filterToCustomQuestionAnswerPairs = (
  answers: IAnswers
): { [questionText: string]: string } => {
  const questionAnswerPairs: { [questionText: string]: string } = {};
  Object.keys(answers).forEach((questionId) => {
    if (isQuestionIdCustom(questionId)) {
      questionAnswerPairs[questionId] = answers[questionId][0];
    }
  });
  return questionAnswerPairs;
};

// Use hard-coded answers in answerOptions array to compute medical logic
export const covidQuestions: { [covidQuestionId: string]: ICovidQuestion } = {
  [CovidQuestionId.LifeThreateningSymptoms]: {
    format: CovidQuestionFormat.Radio,
    questionText: "Do you have any of these life-threatening symptoms?",
    questionBulletpoints: [
      "Bluish lips or face",
      "Severe and constant pain or pressure in the chest",
      "Extreme difficulty breathing (such as gasping for air, being unable to talk without catching your breath, severe wheezing, nostrils flaring)",
      "New disorientation (acting confused)",
      "Unconscious or very difficult to wake up",
      "Slurred speech or difficulty speaking (new or worsening)",
      "New or worsening seizures",
      "Signs of low blood pressure (too weak to stand, dizziness, lightheaded, feeling cold, pale, clammy skin)",
      "Dehydration (dry lips and mouth, not urinating much, sunken eyes)",
    ],
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.FeelingSick]: {
    format: CovidQuestionFormat.Radio,
    questionText: "Are you feeling sick?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.CloseContactAsymp]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "In the last two weeks, did you care for or have close contact (within 6 feet of an infected person for at least 15 minutes) with someone with symptoms of COVID-19, tested for COVID-19, or diagnosed with COVID-19?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.LiveInFacility]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "Do you live in a long-term care facility, nursing home, or homeless shelter?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.HospitalWorker]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "In the last two weeks, have you worked, volunteered, or been a patient in any healthcare setting or work as a first responder? Healthcare facilities include a hospital, medical or dental clinic, long-term care facility, or nursing home.",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.HospitalWorkerPPE]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "Did you wear personal protective equipment (N95 or surgical mask, gown, eye protection) while working or volunteering at the healthcare facility?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.CloseContactSympto]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "In the two weeks before you felt sick, did you care for or have close contact (within 6 feet of an infected person for at least 15 minutes) with someone with symptoms of COVID-19, tested for COVID-19, or diagnosed with COVID-19?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.CovidSymptoms]: {
    format: CovidQuestionFormat.Checkmark,
    questionText: "Do you have any of the following?",
    answerOptions: [
      "Fever or feeling feverish (such as chills, sweating)",
      "Cough",
      "Mild or moderate difficulty breathing",
      "Sore throat",
      "Muscle aches or body aches",
      "Vomiting or diarrhea",
      "New loss of taste or smell",
      "Congestion or runny nose",
      "Other symptoms",
      "None of the above",
    ],
  },
  [CovidQuestionId.TestedPositive]: {
    format: CovidQuestionFormat.Radio,
    questionText: "Have you tested positive for COVID-19 in the past 14 days?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.StateTravelRisk]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      "Have you traveled within a state other than (StateCode) for longer than 24 hours within the past 14 days?",
    answerOptions: ["Yes", "No"],
  },
  [CovidQuestionId.VaccineGeneral]: {
    format: CovidQuestionFormat.Radio,
    questionText:
      'Have you been fully vaccinated for COVID-19? (Some vaccines requires 2 doses to be considered "fully" vaccinated)',
    answerOptions: ["Yes", "No"],
  },
};
