import {
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FirestoreService from "../../../services/firestore";
import {
  Company,
  ICompanyDailyEmailReminders,
  ICompanyResultsOverTime,
  IVaccinatedMember,
  PortalTabType,
} from "../../../types";
import { PortalHeader } from "./PortalHeader";
import TimelineIcon from "@material-ui/icons/Timeline";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EditIcon from "@material-ui/icons/Edit";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import HomeIcon from "@material-ui/icons/Home";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { themeColors } from "../../../styles";
import { AdminEditCompany } from "../../admin/AdminEditCompany";
import { DevErrorBox } from "../../common/DevErrorBox";
import { PortalHome } from "./PortalHome";
import { PortalReportCovidCase } from "./PortalReportCovidCase";
import { PortalEmailReminders } from "./PortalEmailReminders";
import { PortalScreeningResults } from "./PortalScreeningResults";
import { useLocation, useParams } from "react-router";
import uiUtils from "../../../uiUtils";
import { PortalVaccinePassports } from "./PortalVaccinePassports";

interface IManagerPortalProps {}

interface IManagerPortalParams {
  companyId?: string;
}

export const ManagerPortal: React.FunctionComponent<IManagerPortalProps> = (
  props
) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTabValue, setCurrentTabValue] = React.useState<PortalTabType>(
    PortalTabType.Home
  );
  const [companyData, setCompanyData] = useState<Company>();
  const [questionnaireResults, setQuestionnaireResults] = useState<
    ICompanyResultsOverTime | undefined
  >();
  const [
    dailyEmailRemindersData,
    setDailyEmailRemindersData,
  ] = useState<ICompanyDailyEmailReminders>();
  const [vaccinatedMembers, setVaccinatedMembers] = useState<
    IVaccinatedMember[]
  >([]);
  const [open, setOpen] = React.useState(false);

  const currLocation = useLocation();
  const { companyId } = useParams<IManagerPortalParams>();

  useEffect(() => {
    const onCompanyDataLoaded = (company: Company | undefined) => {
      if (company) {
        setCompanyData(company);
        setIsLoading(false);
      } else {
        uiUtils.showSnackbar("Something went wrong. Refresh the page");
      }
    };
    if (currLocation.pathname.includes("/adminGodPortal") && !!companyId) {
      FirestoreService.getCompany(companyId).then(onCompanyDataLoaded);
    } else {
      FirestoreService.getCompanyForManager().then(onCompanyDataLoaded);
    }
  }, [currLocation.pathname, companyId]);

  if (isLoading || !companyData) {
    return <CircularProgress />;
  }

  const fetchAndSetQuestionnaireResults = async (): Promise<void> => {
    const results = await FirestoreService.getCompanyQuestionnaireResultsOverTime(
      companyData.id
    );
    setQuestionnaireResults(results);
  };

  const fetchAndSetVaccinatedMembers = (): void => {
    FirestoreService.getAllVaccinatedMembers(companyData.id).then(
      (fetchedVaccinatedMembers) => {
        setVaccinatedMembers(fetchedVaccinatedMembers);
      }
    );
  };

  const fetchAndSetDailyReminderData = async (): Promise<void> => {
    const reminderData = await FirestoreService.getCompanyEmailReminders(
      companyData.id
    );
    setDailyEmailRemindersData(reminderData);
  };

  const onMenuClick = (newValue: PortalTabType) => () => {
    setCurrentTabValue(newValue);
  };

  const goToEmailRemindersTab = () => {
    setCurrentTabValue(PortalTabType.EmailReminders);
  };

  const goToScreeningResultsTab = () => {
    setCurrentTabValue(PortalTabType.ScreeningResults);
  };

  const goToAlertCovidCaseTab = () => {
    setCurrentTabValue(PortalTabType.AlertCovidCase);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const sharedDrawerProps = {
    style: { width: 240, flexShrink: 0 },
    PaperProps: {
      style: { width: 240, zIndex: 1 },
    },
  };

  const renderTabContent = (): JSX.Element => {
    switch (currentTabValue) {
      case PortalTabType.Home:
        return (
          <PortalHome
            companyId={companyData?.id}
            companyName={companyData.companyName}
            goToEmailRemindersTab={goToEmailRemindersTab}
            questionnaireResults={questionnaireResults}
            fetchAndSetQuestionnaireResults={fetchAndSetQuestionnaireResults}
            goToScreeningResultsTab={goToScreeningResultsTab}
            goToAlertCovidCaseTab={goToAlertCovidCaseTab}
          />
        );
      case PortalTabType.ScreeningResults:
        return (
          <PortalScreeningResults
            questionnaireResults={questionnaireResults}
            fetchAndSetQuestionnaireResults={fetchAndSetQuestionnaireResults}
          />
        );
      case PortalTabType.EmailReminders:
        return (
          <PortalEmailReminders
            companyId={companyData.id}
            noEmailPersist={!!companyData.noEmailPersist}
            dailyEmailReminderData={dailyEmailRemindersData}
            fetchAndSetDailyEmailReminderData={fetchAndSetDailyReminderData}
            setDailyEmailReminderData={setDailyEmailRemindersData}
          />
        );
      case PortalTabType.EditCompanyInfo:
        return <AdminEditCompany companyIdViaProps={companyData.id} />;
      case PortalTabType.AlertCovidCase:
        return (
          <PortalReportCovidCase
            companyName={companyData.companyName}
            questionnaireResults={questionnaireResults}
            fetchAndSetQuestionnaireResults={fetchAndSetQuestionnaireResults}
          />
        );
      case PortalTabType.VaccinePassports:
        return (
          <PortalVaccinePassports
            companyId={companyData.id}
            vaccinatedMembers={vaccinatedMembers}
            fetchAndSetVaccinatedMembers={fetchAndSetVaccinatedMembers}
          />
        );
      default:
        return (
          <DevErrorBox errorMessage={"Unrecognized tab, refresh the page"} />
        );
    }
  };

  const selectedMenuItemStyle: React.CSSProperties = {
    color: themeColors.secondary,
  };

  const getActiveDrawerProps = (tabValue: PortalTabType) => {
    if (currentTabValue === tabValue) {
      return {
        style: selectedMenuItemStyle,
      };
    }

    return {};
  };

  const drawerItems = (
    <>
      <div style={{ overflow: "auto" }}>
        <List
          subheader={<ListSubheader>{companyData.companyName}</ListSubheader>}
        >
          <ListItem onClick={onMenuClick(PortalTabType.Home)} button>
            <ListItemIcon {...getActiveDrawerProps(PortalTabType.Home)}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.Home)}
              primary={`Dashboard`}
            />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader>Screening Tools</ListSubheader>}>
          <ListItem
            onClick={onMenuClick(PortalTabType.ScreeningResults)}
            button
          >
            <ListItemIcon
              {...getActiveDrawerProps(PortalTabType.ScreeningResults)}
            >
              <TimelineIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.ScreeningResults)}
              primary={PortalTabType.ScreeningResults}
            />
          </ListItem>
          <ListItem onClick={onMenuClick(PortalTabType.EmailReminders)} button>
            <ListItemIcon
              {...getActiveDrawerProps(PortalTabType.EmailReminders)}
            >
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.EmailReminders)}
              primary={PortalTabType.EmailReminders}
            />
          </ListItem>
          <ListItem onClick={onMenuClick(PortalTabType.EditCompanyInfo)} button>
            <ListItemIcon
              {...getActiveDrawerProps(PortalTabType.EditCompanyInfo)}
            >
              <EditIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.EditCompanyInfo)}
              primary={PortalTabType.EditCompanyInfo}
            />
          </ListItem>
          <ListItem onClick={onMenuClick(PortalTabType.AlertCovidCase)} button>
            <ListItemIcon
              {...getActiveDrawerProps(PortalTabType.AlertCovidCase)}
            >
              <AnnouncementIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.AlertCovidCase)}
              primary={PortalTabType.AlertCovidCase}
            />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader>Vaccinations</ListSubheader>}>
          <ListItem
            onClick={onMenuClick(PortalTabType.VaccinePassports)}
            button
          >
            <ListItemIcon
              {...getActiveDrawerProps(PortalTabType.VaccinePassports)}
            >
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText
              {...getActiveDrawerProps(PortalTabType.VaccinePassports)}
              primary={PortalTabType.VaccinePassports}
            />
          </ListItem>
        </List>
      </div>
    </>
  );

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <PortalHeader
        onClickMobileMenuToggle={() => setOpen(!open)}
        companyName={companyData.companyName}
        companyLogoUrl={companyData.companyLogoUrl}
        stripUserId={companyData.stripeCustomerId}
      />
      <Hidden smUp implementation="css">
        <Drawer
          {...sharedDrawerProps}
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          onClick={handleDrawerClose}
        >
          {drawerItems}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer {...sharedDrawerProps} variant="permanent">
          <Toolbar />
          {drawerItems}
        </Drawer>
      </Hidden>
      <div style={{ flexGrow: 1 }}>
        <Toolbar />
        <div style={{ padding: "1rem" }}>{renderTabContent()}</div>
      </div>
    </div>
  );
};
