import { Typography } from "@material-ui/core";
import React from "react";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import FaceIcon from "@material-ui/icons/Face";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ErrorIcon from "@material-ui/icons/Error";

interface IUserResultsInfoProps {
  isVaccinated: boolean;
  userName?: string;
  userEmail?: string;
  submissionDate?: string;
  color?: string;
}

export const UserResultsInfo: React.FunctionComponent<IUserResultsInfoProps> = (
  props
) => {
  const { isVaccinated, userName, userEmail, submissionDate, color } = props;
  const renderConfirmationInfoRow = (
    text: string,
    icon: JSX.Element
  ): JSX.Element => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "0.5rem",
        }}
      >
        {icon}
        <Typography variant="subtitle1" style={{ marginLeft: "0.5rem", color }}>
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <div>
      {!!userName &&
        renderConfirmationInfoRow(userName, <FaceIcon style={{ color }} />)}
      {!!userEmail &&
        renderConfirmationInfoRow(
          userEmail,
          <MailOutlineIcon style={{ color }} />
        )}
      {!!submissionDate &&
        renderConfirmationInfoRow(
          submissionDate,
          <AlarmOnIcon style={{ color }} />
        )}
      {renderConfirmationInfoRow(
        isVaccinated ? "Vaccinated" : "Not Vaccinated",
        props.isVaccinated ? (
          <VerifiedUserIcon style={{ color }} />
        ) : (
          <ErrorIcon style={{ color }} />
        )
      )}
    </div>
  );
};
