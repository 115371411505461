import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AdminCompaniesList } from "./components/admin/AdminCompaniesList";
import { AdminHome } from "./components/admin/AdminHome";
import { AdminNewCompany } from "./components/admin/AdminNewCompany";
import { Questionnaire } from "./components/questionnaire/Questionnaire";
import { NavigationRoute } from "./types";
import "./App.css";
import {
  CircularProgress,
  createMuiTheme,
  Snackbar,
  ThemeProvider,
} from "@material-ui/core";
import { authService } from "./services/firestore";
import { AdminLogin } from "./components/admin/AdminLogin";
import { AdminCsvExport } from "./components/admin/AdminCsvExport";
import { AdminEditCompany } from "./components/admin/AdminEditCompany";
import { ManagerSignInSignUp } from "./components/manager/ManagerSignInSignUp";
import { ManagerNewCompanyOnboarding } from "./components/manager/newCompanyOnboarding/ManagerNewCompanyOnboarding";
import { ManagerPortal } from "./components/manager/portal/ManagerPortal";
import { themeColors } from "./styles";
import UIUtils from "./uiUtils";
import { PaymentSuccess } from "./components/manager/portal/PaymentSuccess";
import { VaccineVerification } from "./components/vaccine/VaccineVerification";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.secondary,
    },
  },
});

function App() {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const openSnackbarWithMessage = (message: string): void => {
    setSnackbarMessage(message);
    setIsSnackbarOpen(true);
  };
  useEffect(() => {
    UIUtils.snackbarSetFxn = openSnackbarWithMessage;
    authService.onAuthStateChanged((user) => {
      if (!!user) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
      setIsLoading(false);
    });
  }, []);

  // TODO - protect admin routes correctly
  const adminWrapper = (component: JSX.Element): JSX.Element => {
    return isSignedIn ? component : <AdminLogin />;
  };

  const managerWrapper = (component: JSX.Element): JSX.Element => {
    return isSignedIn ? component : <ManagerSignInSignUp />;
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div>
          <Switch>
            <Route path={NavigationRoute.AdminHome}>
              {adminWrapper(<AdminHome />)}
            </Route>
            <Route path={NavigationRoute.AdminNewCompany}>
              {adminWrapper(<AdminNewCompany />)}
            </Route>
            <Route path={NavigationRoute.AdminCompaniesList}>
              {adminWrapper(<AdminCompaniesList />)}
            </Route>
            <Route path={NavigationRoute.AdminCsvExport}>
              {adminWrapper(<AdminCsvExport />)}
            </Route>
            <Route path={NavigationRoute.AdminEditCompany}>
              {adminWrapper(<AdminEditCompany />)}
            </Route>
            <Route path={NavigationRoute.Questionnaire}>
              <Questionnaire />
            </Route>
            <Route path={NavigationRoute.VaccineVerification}>
              <VaccineVerification />
            </Route>
            <Route path={NavigationRoute.ManagerSignInSignUp}>
              <ManagerSignInSignUp />
            </Route>
            <Route path={NavigationRoute.ManagerNewCompanyOnboarding}>
              <ManagerNewCompanyOnboarding />
            </Route>
            <Route path={NavigationRoute.ManagerPortal}>
              {managerWrapper(<ManagerPortal />)}
            </Route>
            <Route path={NavigationRoute.AdminGodPortal}>
              {adminWrapper(<ManagerPortal />)}
            </Route>
            <Route path={NavigationRoute.PaymentSuccess}>
              {managerWrapper(<PaymentSuccess />)}
            </Route>
            <Route path={"/"}>{managerWrapper(<ManagerPortal />)}</Route>
          </Switch>
          <Snackbar
            open={isSnackbarOpen}
            onClose={() => setIsSnackbarOpen(false)}
            message={snackbarMessage}
            autoHideDuration={3000}
          />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
