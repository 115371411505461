import React from "react";
import { Button, Dialog, DialogTitle, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { CSVReader } from "react-papaparse";
import uiUtils from "../../uiUtils";
import { themeColors } from "../../styles";
import ExampleEmailListPng from "../../client-assets/example-email-list.png";
import { PaperWrapper } from "./PaperWrapper";

interface CsvImportDataRow {
  data: string[];
}

interface IEmailImportDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  handleImportMassEmails: (newEmails: string[]) => void;
}

export const EmailImportDialog: React.FunctionComponent<IEmailImportDialogProps> = (
  props
) => {
  const onFileDownloadError = () => {
    uiUtils.showSnackbar("There was an issue downloading your .csv file");
  };
  const handleCsvDataParse = (data: CsvImportDataRow[]) => {
    const newEmails: string[] = [];
    data.forEach((row) => {
      const email = row.data[0];
      newEmails.push(email);
    });
    props.handleImportMassEmails(newEmails);
    props.closeDialog();
    uiUtils.showSnackbar("Emails successfully imported");
  };
  return (
    <Dialog open={props.isOpen} style={{ padding: "1rem" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <DialogTitle id={`email-import-dialog-title`}>
          {"Import an email list"}
        </DialogTitle>
        <Button onClick={props.closeDialog}>
          <ClearIcon />
        </Button>
      </div>
      <PaperWrapper style={{ margin: "1rem" }}>
        <Typography>
          {
            "Your .csv file should have one single email per row, like this example:"
          }
        </Typography>
        <img src={ExampleEmailListPng} alt={"example email list"} style={{ width: "100%" }} />
      </PaperWrapper>
      <CSVReader onError={onFileDownloadError} onDrop={handleCsvDataParse}>
        <Typography
          variant="h5"
          style={{
            backgroundColor: themeColors.primary,
            color: "white",
            borderRadius: "5px",
            padding: "0.5rem",
          }}
        >
          {"Tap here to select .csv file"}
        </Typography>
      </CSVReader>
    </Dialog>
  );
};
