import { Button, CircularProgress } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import React, { useState } from "react";
import { emailService } from "../../services/firestore";

interface IEmailSelfButtonProps {
  employeeEmail: string;
  employeeName: string;
  submissionDate: Date;
  isClear: boolean;
}

export const EmailSelfButton: React.FunctionComponent<IEmailSelfButtonProps> = (
  props
) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [hasSent, setHasSent] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const sendEmailResultsToSelf = () => {
    setIsSending(true);
    emailService
      .sendResultsToSelf(
        props.employeeEmail,
        props.employeeName,
        props.submissionDate,
        props.isClear
      )
      .then((result) => {
        setHasSent(true);
        setIsSending(false);
      })
      .catch((err) => {
        setIsError(true);
        setIsSending(false);
      });
  };
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={isSending ? <CircularProgress /> : <EmailIcon />}
      style={{ marginTop: "0.5rem" }}
      disabled={isSending || hasSent}
      onClick={hasSent ? undefined : sendEmailResultsToSelf}
    >
      {hasSent
        ? "Sent! Check your email"
        : isError
        ? "Something went wrong, try again?"
        : "Email results to myself"}
    </Button>
  );
};
