import React from 'react'
import { Button, Container, Typography } from "@material-ui/core"
import { Link } from 'react-router-dom'
import { NavigationRoute } from '../../../types'

export const PaymentSuccess = () => {
    return (
        <Container>
            <div style={{ marginTop: '5rem' }}>
                <Typography variant="h4" component="h1">
                    Thank you for subscribing to SimplyScreen and helping the world become a safer place!
                </Typography>
                <div style={{ marginTop: '2rem' }}>
                    <Button variant="contained" color="primary" to={NavigationRoute.ManagerPortal} component={Link} replace={true}>Go Back to SimplyScreen</Button>
                </div>
            </div>
        </Container>
    )
}