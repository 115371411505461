import { Divider, Typography } from "@material-ui/core";
import React from "react";

interface IPortalTabTitleProps {
  title: string;
  subtitle?: string;
}

export const PortalTabTitle: React.FunctionComponent<IPortalTabTitleProps> = (
  props
) => {
  return (
    <div>
      <Typography variant="h5">{props.title}</Typography>
      {props.subtitle && (
        <Typography variant="caption">{props.subtitle}</Typography>
      )}
      <Divider style={{ margin: "1rem 0" }} />
    </div>
  );
};
