import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { PortalTabTitle } from "./PortalTabTitle";
import { IVaccinatedMember, PortalTabType } from "../../../types";
import { SaveButton } from "../../common/SaveButton";
import AddIcon from "@material-ui/icons/Add";
import { themeColors, useBreakpointStyles } from "../../../styles";
import { PaperWrapper } from "../../common/PaperWrapper";
import FirestoreService from "../../../services/firestore";
import uiUtils from "../../../uiUtils";

interface IPortalVaccinePassportsProps {
  companyId: string;
  vaccinatedMembers: IVaccinatedMember[];
  fetchAndSetVaccinatedMembers: () => void;
}

export const PortalVaccinePassports: React.FunctionComponent<IPortalVaccinePassportsProps> = (
  props
) => {
  const { companyId, vaccinatedMembers, fetchAndSetVaccinatedMembers } = props;
  const breakpointStyles = useBreakpointStyles();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [inputFirstName, setInputFirstName] = useState<string>("");
  const [inputLastName, setInputLastName] = useState<string>("");
  const [inputEmail, setInputEmail] = useState<string>("");
  const handleInputFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputFirstName(event.target.value);
  };
  const handleInputLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputLastName(event.target.value);
  };
  const handleInputEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputEmail(event.target.value);
  };

  const addNewVaccinatedMember = () => {
    if (!inputFirstName) {
      uiUtils.showSnackbar("Missing first name");
      return;
    }
    if (!inputLastName) {
      uiUtils.showSnackbar("Missing last name");
      return;
    }
    if (!inputEmail) {
      uiUtils.showSnackbar("Missing email");
      return;
    }

    setIsSaving(true);
    FirestoreService.addNewVaccinatedMember(companyId, {
      firstName: inputFirstName,
      lastName: inputLastName,
      email: inputEmail,
    })
      .then(() => {
        setInputFirstName("");
        setInputLastName("");
        setInputEmail("");
        uiUtils.showSnackbar("Vaccine passport succesfully added!");
        fetchAndSetVaccinatedMembers();
      })
      .catch((err) => {
        console.error(
          `[PortalVaccinePassports].addNewVaccinatedMember failed`,
          { err }
        );
        uiUtils.showSnackbar("Ooops, something went wrong");
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  useEffect(fetchAndSetVaccinatedMembers, []);

  return (
    <React.Fragment>
      <PortalTabTitle title={PortalTabType.VaccinePassports} />
      <PaperWrapper>
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          {"Add a new vaccine passport"}
        </Typography>
        <TextField
          onChange={handleInputFirstNameChange}
          value={inputFirstName}
          variant="outlined"
          id="portal-vaccine-passport-firstname"
          placeholder={"First name"}
          style={{ display: "flex", marginBottom: "0.5rem" }}
        />

        <TextField
          onChange={handleInputLastNameChange}
          value={inputLastName}
          variant="outlined"
          id="portal-vaccine-passport-lastname"
          placeholder={"Last name"}
          style={{ display: "flex", marginBottom: "0.5rem" }}
        />

        <TextField
          onChange={handleInputEmailChange}
          value={inputEmail}
          type="email"
          variant="outlined"
          id="portal-vaccine-passport-email"
          placeholder={"Email"}
          style={{ display: "flex", marginBottom: "1rem" }}
        />
        {isSaving ? (
          <CircularProgress />
        ) : (
          <SaveButton
            onSave={addNewVaccinatedMember}
            buttonLabel={"Add"}
            confirmationDialogLabel={
              "By continuing, you have verified that this person has been vaccinated and that they have shown you documented proof."
            }
            iconOverride={<AddIcon style={{ color: "white" }} />}
            buttonColorOverride={themeColors.primary}
          />
        )}
      </PaperWrapper>
      <PaperWrapper>
        <Typography variant="h6" style={{ marginBottom: "0.5rem" }}>
          {"Existing passports"}
        </Typography>
        <List dense={false}>
          {vaccinatedMembers.map(
            (vaccinatedMember: IVaccinatedMember, index: number) => {
              const { firstName, lastName, email, pinCode } = vaccinatedMember;
              return (
                <ListItem key={`${pinCode}${index}`}>
                  <ListItemAvatar
                    className={breakpointStyles.breakpointHideIfBelowSm}
                  >
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${pinCode} - ${firstName} ${lastName}`}
                    secondary={`${email}`}
                  />
                </ListItem>
              );
            }
          )}
        </List>
      </PaperWrapper>
    </React.Fragment>
  );
};
