import {
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { authService } from "../../../services/firestore";
import { NavigationRoute, StateCode } from "../../../types";
import FirestoreService from "../../../services/firestore";
import { useHistory } from "react-router-dom";
import UIUtils from "../../../uiUtils";

interface INewCompanyOnboardingManagerSignUpProps {
  companyName: string;
  companyEmails: string[];
  companyStateCode: StateCode;
  companyLogo?: File;
}

export const NewCompanyOnboardingManagerSignUp: React.FunctionComponent<INewCompanyOnboardingManagerSignUpProps> = (
  props
) => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [retypePassword, setRetypePassword] = useState<string>("");
  const [hasAttemptedSignUp, setHasAttemptedSignUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const completeOnboarding = async () => {
    setHasAttemptedSignUp(true);
    if (!email || !password || !retypePassword) {
      UIUtils.showSnackbar("All fields must be filled");
      return;
    }
    if (password !== retypePassword) {
      UIUtils.showSnackbar("Passwords do not match");
      return;
    }
    setIsLoading(true);
    const newManagerUser = await authService.signUpWithEmailAndPassword(
      email,
      password
    );
    if (newManagerUser.user?.uid) {
      await FirestoreService.addNewManagerUserWithNewCompany(
        newManagerUser.user.uid,
        props.companyName,
        props.companyEmails,
        props.companyStateCode,
        props.companyLogo
      );
      history.push(NavigationRoute.ManagerPortal);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h5" style={{ marginBottom: "1rem" }}>
        {"Create your free account."}
      </Typography>
      <Typography style={{ marginBottom: "1rem" }}>
        {
          "Keep your workspace safe and compliant. Start screening and contact tracing today."
        }
      </Typography>
      <TextField
        value={email}
        label={"Email"}
        type="email"
        id="new-manager-signup-email"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
        }}
        error={hasAttemptedSignUp && !email}
        required={true}
      />
      <TextField
        value={password}
        label={"Password"}
        type="password"
        id="new-manager-signup-password"
        autoComplete="current-password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(event.target.value);
        }}
        error={hasAttemptedSignUp && !password}
        required={true}
      />
      <TextField
        value={retypePassword}
        label={"Retype Password"}
        type="password"
        id="new-manager-signup-password-retype"
        autoComplete="current-password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRetypePassword(event.target.value);
        }}
        error={hasAttemptedSignUp && !retypePassword}
        required={true}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={completeOnboarding}
        style={{ color: "white", marginTop: "1rem" }}
      >
        Sign Up
      </Button>
    </div>
  );
};
