import React from "react";
import { PaperWrapper } from "../common/PaperWrapper";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NavigationRoute } from "../../types";
import { authService } from "../../services/firestore";

interface IAdminHomeProps {}

export const AdminHome: React.FunctionComponent<IAdminHomeProps> = (props) => {
  return (
    <div>
      <PaperWrapper>
        <Link to={NavigationRoute.AdminNewCompany}>
          <Button>{"Add new company"}</Button>
        </Link>
      </PaperWrapper>
      <PaperWrapper>
        <Link to={NavigationRoute.AdminCompaniesList}>
          <Button>{"See all companies"}</Button>
        </Link>
      </PaperWrapper>
      <Button onClick={authService.signOut}>{"Log out of account"}</Button>
    </div>
  );
};
