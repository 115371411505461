import { Button } from "@material-ui/core";
import React, { useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { themeColors } from "../../styles";

interface ISaveButtonProps {
  onSave: () => void;
  buttonLabel: string;
  confirmationDialogLabel: string;
  onCancel?: () => void;
  style?: React.CSSProperties;
  iconOverride?: JSX.Element;
  buttonColorOverride?: string;
}

export const SaveButton: React.FunctionComponent<ISaveButtonProps> = (
  props
) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  return (
    <div style={props.style}>
      <Button
        onClick={openDialog}
        variant="contained"
        style={{ backgroundColor: props.buttonColorOverride || themeColors.tertiary, color: "white" }}
        startIcon={props.iconOverride || <SaveIcon style={{ color: "white" }} />}
      >
        {props.buttonLabel}
      </Button>
      <ConfirmationDialog
        title={props.confirmationDialogLabel}
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        onYes={props.onSave}
        onNo={props.onCancel}
      />
    </div>
  );
};
